import { InitializeFilterDropdown, GetSelectedDropdownValues, ResetDropdownFilters, IFilter } from '../../../../shared/scripts/filtering';
import { Unique } from '../../../../shared/scripts/array-functions';
import { HrrnContact, HrrnResearchProject, HrrnDataSource, HrrnFile, PacrContact, PacrFormObj, PacrDataSource } from './pacr-form.entities';
import { plainToClass } from 'class-transformer';
import { ContactSelect } from './contact-select'
import { FileUpload } from './file-upload';
import { PacrTable } from './pacr-table';
import { DataSourceSelect } from './data-source-select';

declare var $: any;

export class PacrForm {

    CurrentForm: PacrFormObj;
    CurrentContacts: PacrContact[];
    CurrentDataSources: PacrDataSource[];
    CurrentFiles: HrrnFile[];

    CurrentUser: HrrnContact;
    HrrnContacts: HrrnContact[];
    HrrnResearchProjects: HrrnResearchProject[] = [];
    PacrDataSources: HrrnDataSource[];

    CoInvestigators: ContactSelect;
    FileUpload: FileUpload;
    AssociatedPITable: PacrTable;
    DataSourceSelect: DataSourceSelect;

    ReviewLeadRPTable: PacrTable;
    ReviewAssociatedPITable: PacrTable;
    ReviewCoInvestigatorsTable: PacrTable;
    ReviewAssociatedRPTable: PacrTable;
    ReviewUploadedFilesTable: PacrTable;
    ReviewDataSourcesTable: PacrTable;

    constructor(HrrnContactsJson: any, CurrentUserEmail: any, CurrentForm: any, PacrDataSourcesJson: any, CurrentContacts: any, CurrentDataSources: any, CurrentFiles: any, UserGuideJson: any) {
        //document.ready
        $(() => {

            this.CurrentForm = plainToClass(PacrFormObj, <PacrFormObj>CurrentForm);
            this.CurrentContacts = plainToClass(PacrContact, <PacrContact[]>CurrentContacts);
            this.CurrentDataSources = plainToClass(PacrDataSource, <PacrDataSource[]>CurrentDataSources);
            this.CurrentFiles = plainToClass(HrrnFile, <HrrnFile[]>CurrentFiles);

            this.HrrnContacts = plainToClass(HrrnContact, <HrrnContact[]>HrrnContactsJson);
            this.HrrnContacts.sort((x1: HrrnContact, x2: HrrnContact) => x1.LastName.localeCompare(x2.LastName));
            this.HrrnContacts.map((data: HrrnContact) => {
                data.initialize();
                return data;
            });

            this.PacrDataSources = plainToClass(HrrnDataSource, <HrrnDataSource[]>PacrDataSourcesJson);
            this.PacrDataSources.sort((x1: HrrnDataSource, x2: HrrnDataSource) => x1.Name.localeCompare(x2.Name));
            this.PacrDataSources.map((data: HrrnDataSource) => {
                data.initiialize();
                return data;
            })

            // Prepopulate Lead Researcher Fields (if in HRRN Network, use data in database, if not, use login info)
            let foundUser = this.HrrnContacts.filter((contact) => contact.Email.toLowerCase() == CurrentUserEmail.toLowerCase());
            if (foundUser.length > 0) this.CurrentUser = foundUser[0];
            else {
                let firstName = "";
                let lastName = "";

                if (this.CurrentForm.SubmitterFirstName != null) {
                    firstName = this.CurrentForm.SubmitterFirstName;
                }

                if (this.CurrentForm.SubmitterLastName != null) {
                    lastName = this.CurrentForm.SubmitterLastName;
                }

                this.CurrentUser = new HrrnContact(firstName, lastName, CurrentUserEmail, "No Associated HRRN Research Project", "");
            }


            this.initializeResearchProjects();

            this.initializeFileUploads();
            this.initializeAutoFillTables();
            this.initializeContactDropdowns();
            this.initializeDataSourceDropdowns();
            this.initializeNavigation();
            this.initializeLabels();
            this.initializeSaveBtn();
            this.initializeSubmitBtn();

            this.prepopulateUserFields();
            this.prepopulateForm();

            // Don't close the alert modal by clicking on background
            $('#pacrModal').modal({ backdrop: 'static', keyboard: false, show: false });

            // User Guide
            if (UserGuideJson.length > 0) {
                $("#user-guide-btn").prop("href", UserGuideJson[0].link);
            }
            else {
                $("#user-guide-btn").addClass("d-none")
            }
            
        });
    }

    initializeNavigation() {
        let page = this;

        // Menu Navigation
        $(".pacr-tab").click(function () {
            page.resetValidation();

            let id: string = $(this).prop('id');

            // hide + show correct form section
            $(".form-section-block").removeClass("active");
            $(`.form-section-block.${id}`).addClass("active");

            // update menu styling
            $(".pacr-menu-item").removeClass("active");
            $(this).parent().addClass("active");

            // enable links to previous sections
            $(this).removeClass("disabled");
            $(this).parent().prevAll(".pacr-menu-item").find(".pacr-tab").removeClass("disabled");

            // disable links to next sections
            $(this).parent().nextAll(".pacr-menu-item").find(".pacr-tab").addClass("disabled");

            // scroll up to top of form section
            $("html, body").animate({
                scrollTop: $("#pacr-form-section").offset().top - 30
            }, 600);

            if (id.includes("review")) {
                page.populateReviewTable();
            }
        });

        // Next + Previous Page Button Navigation
        $(".prev-btn").on("click", function () {

            let btnId: string = $(this).prop('id');
            let tabId: string = "#" + btnId.replace("prev-", "");

            // get previous tab + click
            let targetTab = $(tabId).parent().prev().find(".pacr-tab");
            if (targetTab.length > 0) targetTab.trigger("click");
        });

        $(".next-btn").on("click", function () {
            let isValid = page.formSectionValidation();
            if (!isValid) {
                $("html, body").animate({
                    scrollTop: $(".is-invalid").first().siblings(".required-label").offset().top - 30
                }, 600);
                return; // don't navigate if invalid
            }

            let btnId: string = $(this).prop('id');
            let tabId: string = "#" + btnId.replace("next-", "");

            // get next tab + click
            let targetTab = $(tabId).parent().next().find(".pacr-tab");
            if (targetTab.length > 0) targetTab.trigger("click");

            // next Section save
            //page.nextSectionSave();
        });

        // Review Links
        $(".review-link").on("click", function () {
            let linkId: string = $(this).prop('id');
            let tabId: string = "#" + linkId.replace("review-", "");
            let targetTab = $(tabId);
            if (targetTab.length > 0) targetTab.trigger("click");
        })
    }

    formSectionValidation(): boolean {
        // Reset any existing validation classes
        this.resetValidation();

        let isValid = true;

        // Get All Visible input, textarea, select fields that are required
        let requiredFields = $(".required-field:visible");

        // For each element, check if it is valid
        requiredFields.each(function () {
            if ($(this).val() && $(this).val() != "") {
                $(this).addClass("is-valid");
            }
            else {
                $(this).addClass("is-invalid");
                $(this).siblings(".error-text").removeClass("d-none");
                isValid = false;
            }
        })

        return isValid;
    }

    resetValidation(): void {
        // Get ALL input, textarea, select fields that are required (all sections)
        let requiredFields = $(".required-field");

        // remove 'is-valid' and 'is-invalid' classes
        requiredFields.removeClass("is-valid");
        requiredFields.removeClass("is-invalid");

        // hide error text
        requiredFields.siblings(".error-text").addClass("d-none");
    }

    initializeContactDropdowns(): void {
        this.CoInvestigators = new ContactSelect("#contact-search", this.HrrnContacts, this.HrrnResearchProjects, () => {
            // Style Research Center Dropdowns
            $('.pacr-dropdown').on('show.bs.select', function () {
                $("div.pacr-dropdown").find(".dropdown-item").addClass("pacr-dropdown-item");
            });

            // Prepopulate
            let currentCoInvestigators: HrrnContact[] = [];
            this.CurrentContacts.filter(contact => contact.ContactType == "Co-Investigator / Collaborator").forEach(contact => {
                currentCoInvestigators.push(new HrrnContact(contact.FirstName, contact.LastName, contact.Email, contact.Affiliation, contact.Institution))
            });
            if (currentCoInvestigators.length > 0) {
                this.CoInvestigators.addedContacts = currentCoInvestigators;
                this.CoInvestigators.drawTable();
            }
        });   
    }

    initializeDataSourceDropdowns(): void {
        this.DataSourceSelect = new DataSourceSelect("#data-source-search", this.PacrDataSources, () => {

            // Prepopulate
            let currentDataSources: HrrnDataSource[] = [];
            this.CurrentDataSources.forEach(dataSource => {
                currentDataSources.push(new HrrnDataSource(dataSource.Name, dataSource.SourceType))
            });
            if (currentDataSources.length > 0) {
                this.DataSourceSelect.addedDataSources = currentDataSources;
                this.DataSourceSelect.drawTable();
            }
        })
    }

    initializeFileUploads(): void {
        this.FileUpload = new FileUpload("#pacr-file-upload-block", () => {

            // prepopulate    
            if (this.CurrentFiles) {
                this.FileUpload.uploadedFiles = this.CurrentFiles;
                this.FileUpload.drawGallery();
            }
        });
    }

    initializeAutoFillTables(): void {
        this.AssociatedPITable = new PacrTable("#associated-principal-investigators-table", "contact", () => {
            // set the event associated with the associatedPITable
            $("#lead-research-project").on("change", () => {

                let projectName = $("#lead-research-project").val();
                let researchProject = this.HrrnResearchProjects.filter(project => project.Name == projectName)[0];

                if (researchProject) {
                    this.AssociatedPITable.updateData(researchProject.PIs);
                }
                else this.AssociatedPITable.updateData([]);
            });

            $("#lead-research-project").trigger("change");      
        });   

        this.ReviewLeadRPTable = new PacrTable("#review-lead-research-project-table", "research-project");
        this.ReviewAssociatedPITable = new PacrTable("#review-associated-pi-table");
        this.ReviewCoInvestigatorsTable = new PacrTable("#review-coinvestigators-table");
        this.ReviewAssociatedRPTable = new PacrTable("#review-associated-rp-table", "research-project");
        this.ReviewUploadedFilesTable = new PacrTable("#review-uploaded-files-table", "file");
        this.ReviewDataSourcesTable = new PacrTable("#review-data-sources-table", "data-source");
        
    }

    initializeResearchProjects(): void {
        //A Comparative Evaluation of Overdose Prevention Programs in New York City and Rhode Island
        this.HrrnResearchProjects.push(new HrrnResearchProject("A Comparative Evaluation of Overdose Prevention Programs in New York City and Rhode Island (SAFER Study)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "magdalena.cerda@nyulangone.org" || contact.Email.toLowerCase() == "brandon_marshall@brown.edu")));
        //A Network-Based, Mixed Methods Study to Identify and Support Multiple Overdose Responders and Inform Overdose Prevention Interventions
        this.HrrnResearchProjects.push(new HrrnResearchProject("A Network-Based, Mixed Methods Study to Identify and Support Multiple Overdose Responders and Inform Overdose Prevention Interventions (Project H.E.R.O.)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "karlawagner@unr.edu")));
        //Assessing the Reach, Effectiveness, and Implementation of Multiple Harm Reduction Interventions
        this.HrrnResearchProjects.push(new HrrnResearchProject("Assessing the Reach, Effectiveness, and Implementation of Multiple Harm Reduction Interventions (SF Moon Study)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "akral@rti.org")));
        //Culturally Responsive Integrated Harm Reduction Services for Black and Latinx People Who use Drugs
        this.HrrnResearchProjects.push(new HrrnResearchProject("Undoing Harm and Integrating Services (Project Unite)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "ayana.jordan@nyulangone.org")));
        //Expansion of Mail-Delivered Harm Reduction Services in the U.S.
        this.HrrnResearchProjects.push(new HrrnResearchProject("Harm Reduction Services Offered Through Mail-Delivery Expansion (HOME)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "czb2002@med.cornell.edu")));
        //Implementing and Evaluating the Impact of Novel Mobile Harm Reduction Services on Overdose Among Women Who Use Drugs (SHOUT)
        this.HrrnResearchProjects.push(new HrrnResearchProject("Implementing and Evaluating the Impact of Novel Mobile Harm Reduction Services on Overdose Among Women Who Use Drugs (SHOUT)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "ssherman@jhu.edu")));
        //Mobile Health Strategies to Support Longitudinal Engagement in Harm Reduction Services
        this.HrrnResearchProjects.push(new HrrnResearchProject("Mobile Health Strategies to Support Longitudinal Engagement in Harm Reduction Services (ENHANCE)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "dseal@tulane.edu" || contact.Email.toLowerCase() == "rpw@medicine.wisc.edu" || contact.Email.toLowerCase() == "gicquelais@wisc.edu")) );
        //Peers Expanding Engagement in Methamphetamine Harm-Reduction with Contingency Management (PEER-CM)
        this.HrrnResearchProjects.push(new HrrnResearchProject("Peers Expanding Engagement in Methamphetamine Harm-Reduction with Contingency Management (PEER-CM)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "korthuis@ohsu.edu")));
        //Promoting Remote Harm Reduction and Secondary Services in Rural Settings (PROMOTE)
        this.HrrnResearchProjects.push(new HrrnResearchProject("Promoting Remote Harm Reduction and Secondary Services in Rural Settings (PROMOTE)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "mpho@bsd.uchicago.edu" || contact.Email.toLowerCase() == "mmamiti@uic.edu")));
        //Teaching Harm Reduction In Vulnerable Environments (THRIVE)
        this.HrrnResearchProjects.push(new HrrnResearchProject("Teaching Harm Reduction In Vulnerable Environments (THRIVE)",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "jacqueline.wilson1@pennmedicine.upenn.edu")));
        //Coordination Center
        this.HrrnResearchProjects.push(new HrrnResearchProject("Coordination Center",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "eoga@rti.org" || contact.Email.toLowerCase() == "jcance@rti.org")));
        //NIH/NIDA
        this.HrrnResearchProjects.push(new HrrnResearchProject("NIH/NIDA",
            this.HrrnContacts.filter(contact => contact.Email.toLowerCase() == "julia.zur@nih.gov")));
    }

    createFormObj(): PacrFormObj {
        // Set Display PacerId
        let pacrFormId = this.CurrentForm.Id.toString().padStart(3, '0');
        if ($("#lead-research-last-name").val() != "") pacrFormId += `_${$("#lead-research-last-name").val()}`;

        let associatedResearchProjectsArr: string[] = [];
        this.CoInvestigators.associatedRPTable.researchProjectList.forEach(project => {
            if (!associatedResearchProjectsArr.includes(project.Name)) {
                associatedResearchProjectsArr.push(project.Name)
            }
        });
        let associatedResearchProjectsString = associatedResearchProjectsArr.join("|");

        let currentForm: PacrFormObj = {
            // Form Information
            Id: this.CurrentForm.Id,
            PacrFormId: pacrFormId,
            LastUpdateDate: new Date(),
            CreateDate: this.CurrentForm.CreateDate ? this.CurrentForm.CreateDate : new Date(),
            Status: "In Draft",
            // Submitter Information
            SubmitterFirstName: this.CurrentForm.SubmitterFirstName ? this.CurrentForm.SubmitterFirstName : "",
            SubmitterLastName: this.CurrentForm.SubmitterLastName ? this.CurrentForm.SubmitterLastName : "",
            SubmitterEmail: this.CurrentForm.SubmitterEmail ? this.CurrentForm.SubmitterEmail : "",
            // General Information
            ProposalType: $("#proposal-type").val() ? $("#proposal-type").val().toString() : "",
            Title: $("#proposal-title").val() ? $("#proposal-title").val().toString() : "",
            // Lead Researcher Information
            LeadFirstName: $("#lead-research-first-name").val() ? $("#lead-research-first-name").val().toString() : "",
            LeadLastName: $("#lead-research-last-name").val() ? $("#lead-research-last-name").val().toString() : "",
            LeadEmail: $("#lead-research-email").val() ? $("#lead-research-email").val().toString() : "",
            LeadPhone: $("#lead-research-phone").val() ? $("#lead-research-phone").val().toString() : "",
            LeadResearchProject: this.CoInvestigators.associatedRPTable.leadResearchProject,
            LeadResearchProjectName: this.CoInvestigators.associatedRPTable.leadResearchProject?.Name,
            AssociatedPIs: this.AssociatedPITable.contactList,
            // Investigators
            CoInvestigators: this.CoInvestigators.addedContacts,
            AssociatedRPs: this.CoInvestigators.associatedRPTable.researchProjectList,
            AssociatedResearchProjects: associatedResearchProjectsString,
            // Proposal Details
            ProposalSummary: $("#proposal-summary").val() ? $("#proposal-summary").val().toString() : "",
            ProposalSpecificAims: $("#proposal-specific-aims").val() ? $("#proposal-specific-aims").val().toString() : "",
            PrimarySecondaryOutcomes: $("#primary-secondary-outcomes").val() ? $("#primary-secondary-outcomes").val().toString() : "",
            DataSources: this.DataSourceSelect.addedDataSources,
            ResearchMethodology: $("#research-methodology-summary").val() ? $("#research-methodology-summary").val().toString() : "",
            DataHandling: $("#data-handling-procedures").val() ? $("#data-handling-procedures").val().toString() : "",
            IrbRequirements: $("#irb-requirements").val() ? $("#irb-requirements").val().toString() : "",
            ExpectedTimeline: $("#expected-timeline").val() ? $("#expected-timeline").val().toString() : "",
            // Additional Information
            CCSupport: $("#cc-support").val() ? $("#cc-support").val().toString() : "",
            UploadedFiles: this.FileUpload.files,
            Comments: $("#additional-comments").val() ? $("#additional-comments").val().toString() : ""
        }; 

        return currentForm;
           
     
    }

    createContactObj(): PacrContact[] {
        let contactList: PacrContact[] = [];
        // Lead Researcher Principal Investigator (based on Lead Researcher HRRN Research Project)
        if (this.CoInvestigators.associatedRPTable.leadResearchProject) {
            this.CoInvestigators.associatedRPTable.leadResearchProject.PIs.forEach(investigator => {
                contactList.push(new PacrContact(this.CurrentForm.Id, "Lead Researcher PI", investigator));
            })
        }

        // Associated Principal Investigators (Principal Investigators of Associated Research Projects)
        if (this.CoInvestigators.associatedRPTable.researchProjectList) {
            this.CoInvestigators.associatedRPTable.researchProjectList.forEach(project => {
                project.PIs.forEach(investigator => contactList.push(new PacrContact(this.CurrentForm.Id, "Associated Research Project PI", investigator)))
            })
        }

        // Co-Investigators (Added By User)
        if (this.CoInvestigators.addedContacts) {
            this.CoInvestigators.addedContacts.forEach(contact => contactList.push(new PacrContact(this.CurrentForm.Id, "Co-Investigator / Collaborator", contact)))
        }

        // Remove Duplicates
        let returnContactList: PacrContact[] = [];
        contactList.forEach(contact => {
            if (returnContactList.findIndex(returnContact => returnContact.Email == contact.Email && returnContact.ContactType == contact.ContactType) == -1) {
                returnContactList.push(contact);
            }
        })

        // Return
        return returnContactList;
    }

    createDataSourceObj(): PacrDataSource[] {
        let pacrFormDataSourceList: PacrDataSource[] = [];

        this.DataSourceSelect.addedDataSources.forEach((dataSource) => {
            pacrFormDataSourceList.push(new PacrDataSource(this.CurrentForm.Id, dataSource.Name, dataSource.SourceType));
        })

        return pacrFormDataSourceList;
    }

    populateReviewTable(): void {
        let currentForm: PacrFormObj = this.createFormObj();

        // Set Text Fields
        $("#review-proposal-type").text(currentForm.ProposalType);
        $("#review-proposal-title").text(currentForm.Title);

        $("#review-lead-research-first-name").text(currentForm.LeadFirstName);
        $("#review-lead-research-last-name").text(currentForm.LeadLastName);
        $("#review-lead-research-email").text(currentForm.LeadEmail);
        $("#review-lead-research-phone").text(currentForm.LeadPhone);

        $("#review-proposal-summary").text(currentForm.ProposalSummary);
        $("#review-proposal-specific-aims").text(currentForm.ProposalSpecificAims);
        $("#review-primary-secondary-outcomes").text(currentForm.PrimarySecondaryOutcomes);
        $("#review-research-methodology-summary").text(currentForm.ResearchMethodology);
        $("#review-data-handling-procedures").text(currentForm.DataHandling);
        $("#review-irb-requirements").text(currentForm.IrbRequirements);
        $("#review-expected-timeline").text(currentForm.ExpectedTimeline);

        $("#review-cc-support").text(currentForm.CCSupport);
        $("#review-additional-comments").text(currentForm.Comments);

        // Set Tables
        this.ReviewLeadRPTable.updateData([currentForm.LeadResearchProject]);
        this.ReviewAssociatedPITable.updateData(currentForm.AssociatedPIs);
        this.ReviewCoInvestigatorsTable.updateData(currentForm.CoInvestigators);
        this.ReviewAssociatedRPTable.updateData(currentForm.AssociatedRPs);
        this.ReviewUploadedFilesTable.updateData(currentForm.UploadedFiles);
        this.ReviewDataSourcesTable.updateData(currentForm.DataSources);

    }

    initializeLabels(): void {
        $("#proposal-type").on("change", function () {
            let proposalType = $(this).val();
            if (proposalType) {
                let titleString = proposalType.toString();
                let lowerString = proposalType.toString().toLowerCase();
                $(".proposal-type-text").text(titleString);
                $(".proposal-type-text-lower").text(lowerString);
            }
        });
    }

    initializeSaveBtn(): void {
        $(".pacr-save-btn").on("click", () => {
            let formObj = this.createFormObj();
            let contactObj = this.createContactObj();
            let dataSourceObj = this.createDataSourceObj();

            this.saveFormRequest(formObj, contactObj, dataSourceObj);        
        })
      
    }

    initializeSubmitBtn(): void {
        $(".submit-btn").on("click", () => {
            // Set Text
            $("#pacrModal").find(".modal-title").text("Submit Form")

            let bodyContent = 
                `<div class="text-center">
                    <p>Are you sure you are ready to submit this proposal? Once the form is submitted it will be sent to the review committee.</p>
                </div>`

            let footerContent =
                `<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn butn py-2 submit-confirm-btn"><span>Confirm</span></button>`

            $("#pacrModal").find(".modal-body").html(bodyContent);
            $("#pacrModal").find(".modal-footer").html(footerContent);
            $("#pacrModal").find(".modal-footer").removeClass("d-none");

            // Submission
            $("#pacrModal").find(".submit-confirm-btn").on("click", () => {
                let formObj = this.createFormObj();
                formObj.SubmissionDate = new Date();
                formObj.Status = "Submitted";
                let contactObj = this.createContactObj();
                let dataSourceObj = this.createDataSourceObj();

                this.submitFormRequest(formObj, contactObj, dataSourceObj);           
            })

            // Show Modal
            $('#pacrModal').modal('show');
        })
    }

    prepopulateUserFields(): void {
        $("#lead-research-first-name").val(this.CurrentUser.FirstName);
        $("#lead-research-last-name").val(this.CurrentUser.LastName);
        $("#lead-research-email").val(this.CurrentUser.Email);

        if (this.CurrentUser?.Affiliation && this.CurrentUser?.Affiliation != "") {
            $("#lead-research-project").val(this.CurrentUser.Affiliation);
            // change is triggered in table callback
        }
        else {
            $("#lead-research-project").val("No Associated HRRN Research Project");
            // change is triggered in table callback
        }
    }

    prepopulateForm(): void {
        // Select Fields
        this.CurrentForm.ProposalType != "" && this.CurrentForm.ProposalType != null ? $("#proposal-type").val(this.CurrentForm.ProposalType).trigger("change") : false;
        this.CurrentForm.LeadResearchProjectName != "" && this.CurrentForm.LeadResearchProjectName != null ? $("#lead-research-project").val(this.CurrentForm.LeadResearchProjectName) : false; // trigger change in callback

        // Text Fields
        this.CurrentForm.Title != "" && this.CurrentForm.Title != null? $("#proposal-title").val(this.CurrentForm.Title) : false;
        this.CurrentForm.LeadFirstName != "" && this.CurrentForm.LeadFirstName != null? $("#lead-research-first-name").val(this.CurrentForm.LeadFirstName) : false;
        this.CurrentForm.LeadLastName != "" && this.CurrentForm.LeadLastName != null? $("#lead-research-last-name").val(this.CurrentForm.LeadLastName) : false;
        this.CurrentForm.LeadEmail != "" && this.CurrentForm.LeadEmail != null ? $("#lead-research-email").val(this.CurrentForm.LeadEmail) : false;
        this.CurrentForm.LeadPhone != "" && this.CurrentForm.LeadPhone != null ? $("#lead-research-phone").val(this.CurrentForm.LeadPhone) : false;
        this.CurrentForm.ProposalSummary != "" && this.CurrentForm.ProposalSummary != null ? $("#proposal-summary").val(this.CurrentForm.ProposalSummary) : false;
        this.CurrentForm.ProposalSpecificAims != "" && this.CurrentForm.ProposalSpecificAims != null ? $("#proposal-specific-aims").val(this.CurrentForm.ProposalSpecificAims) : false;
        this.CurrentForm.PrimarySecondaryOutcomes != "" && this.CurrentForm.PrimarySecondaryOutcomes != null ? $("#primary-secondary-outcomes").val(this.CurrentForm.PrimarySecondaryOutcomes) : false;
        this.CurrentForm.ResearchMethodology != "" && this.CurrentForm.ResearchMethodology != null ? $("#research-methodology-summary").val(this.CurrentForm.ResearchMethodology) : false;
        this.CurrentForm.DataHandling != "" && this.CurrentForm.DataHandling != null ? $("#data-handling-procedures").val(this.CurrentForm.DataHandling) : false;
        this.CurrentForm.IrbRequirements != "" && this.CurrentForm.IrbRequirements != null ? $("#irb-requirements").val(this.CurrentForm.IrbRequirements) : false;
        this.CurrentForm.ExpectedTimeline != "" && this.CurrentForm.ExpectedTimeline != null ? $("#expected-timeline").val(this.CurrentForm.ExpectedTimeline) : false;
        this.CurrentForm.CCSupport != "" && this.CurrentForm.CCSupport != null ? $("#cc-support").val(this.CurrentForm.CCSupport) : false;
        this.CurrentForm.Comments != "" && this.CurrentForm.Comments != null ? $("#additional-comments").val(this.CurrentForm.Comments) : false;       
     
    }

    saveFormRequest(pacrFormData: any, contactData: any[], dataSources: any[]): void {
        //console.log("Should Update Files: ", this.FileUpload.shouldUpdateFiles);
        //console.log(formData);
        //console.log(contactData);

        let isFileUploadComplete = false;
        let isFormSaveComplete = false;

        // Set Text
        $("#pacrModal").find(".modal-title").text("Save Progress")
        $("#pacrModal").find(".close").addClass("d-none");

        let bodyContentBefore =
            `<div class="text-center">
                    <h6>Saving <span class="pl-1"><i class="fa-solid fa-spinner spin"></i></span></h6>
                    <p>Large files may take a bit to upload.</p>
                </div>`
        let bodyContentAfter =
            `<div class="text-center">
                    <h6 class="mb-0">Saved!</h6>
                </div>`

        let errorContentAfter =
            `<div class="text-center">
                    <h6 class="mb-0">Oops! An Error has occurred while saving, please try again later.</h6>
                </div>`

        let footerContentBefore = "";
        let footerContentAfter = `<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>`

        $("#pacrModal").find(".modal-body").html(bodyContentBefore);
        $("#pacrModal").find(".modal-footer").html(footerContentBefore);
        $("#pacrModal").find(".modal-footer").addClass("d-none"); 

        // Show Modal
        $('#pacrModal').modal('show');

        // Handle File Uploads 
        if (this.FileUpload.shouldUpdateFiles) {
            let formData: FormData = new FormData();

            let files: File[] = this.FileUpload.files;

            for (var i = 0; i < files.length; i++) {
                let key: string = this.CurrentForm.Id.toString() + "/" + files[i].name;
                formData.append(key, files[i]);
            }

            let excludedfilesArr = [];
            this.FileUpload.uploadedFiles.forEach(file => excludedfilesArr.push(file.id));
            let excludedfilestring = excludedfilesArr.join("|");

            $.ajax({
                url: '/secure/pacrs/pacr-form/pacr-form?handler=UploadFiles',
                method: "POST",
                //dataType: "json",
                cache: false,
                contentType: false,
                processData: false,
                data: formData,
                //files: formData,
                headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val(), "FormId": this.CurrentForm.Id.toString(), "ExcludedFiles": excludedfilestring },
                success: (response) => {
                    //console.log(response);
                    this.FileUpload.shouldUpdateFiles = false;
                    isFileUploadComplete = true;

                    if (isFormSaveComplete) {
                        $("#pacrModal").find(".modal-body").html(bodyContentAfter);
                        $("#pacrModal").find(".modal-footer").html(footerContentAfter);
                        $("#pacrModal").find(".modal-footer").removeClass("d-none");
                        $("#pacrModal").find(".close").removeClass("d-none");
                    }
                },
                error: (response) => {
                    console.log("error", response);
                    $("#pacrModal").find(".modal-body").html(errorContentAfter);
                    $("#pacrModal").find(".modal-footer").removeClass("d-none");
                    alert("An unexpected error occurred. Please try again.");
                }
            });
        }
        else {
            isFileUploadComplete = true;
        }
        

        // Handle Form Save
        $.ajax({
            url: '/secure/pacrs/pacr-form/pacr-form?handler=SaveForm',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(pacrFormData), contactData: JSON.stringify(contactData), dataSources: JSON.stringify(dataSources) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                //console.log(response);
                isFormSaveComplete = true;

                if (isFileUploadComplete) {
                    $("#pacrModal").find(".modal-body").html(bodyContentAfter);
                    $("#pacrModal").find(".modal-footer").html(footerContentAfter);
                    $("#pacrModal").find(".modal-footer").removeClass("d-none");
                    $("#pacrModal").find(".close").removeClass("d-none");
                }             
            },
            error: (response) => {
                console.log("error", response);
                $("#pacrModal").find(".modal-body").html(errorContentAfter);
                $("#pacrModal").find(".modal-footer").removeClass("d-none");
                alert("An unexpected error occurred. Please try again.");
            }
        });      
        
    }

    submitFormRequest(pacrFormData: any, contactData: any, dataSources: any[]): void {
        /*console.log(pacrFormData);
        console.log(contactData);*/

        let isFileUploadComplete = false;
        let isFormSaveComplete = false;

        // Set Text
        let bodyContentBefore =
            `<div class="text-center">
                    <h6>Submitting Form <span class="pl-1"><i class="fa-solid fa-spinner spin"></i></span></h6>
                    <p>Large files may take a bit to upload.</p>
                </div>`
        let bodyContentAfter =
            `<div class="text-center">
                    <h6 class="mb-0">Submitted!</h6>
                </div>`

        let errorContentAfter =
            `<div class="text-center">
                    <h6 class="mb-0">Oops! An Error has occurred while saving, please try again later.</h6>
                </div>`

        let footerContentBefore = "";
        let footerContentAfter = `<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>`

        $("#pacrModal").find(".modal-body").html(bodyContentBefore);
        $("#pacrModal").find(".modal-footer").html(footerContentBefore);
        $("#pacrModal").find(".modal-footer").addClass("d-none");
        $("#pacrModal").find(".close").addClass("d-none");

        // Show Modal
        $('#pacrModal').modal('show');

        // Handle File Upload
        if (this.FileUpload.shouldUpdateFiles) {
            let formData: FormData = new FormData();

            let files: File[] = this.FileUpload.files;

            for (var i = 0; i < files.length; i++) {
                let key: string = this.CurrentForm.Id.toString() + "/" + files[i].name;
                formData.append(key, files[i]);
            }

            let excludedfilesArr = [];
            this.FileUpload.uploadedFiles.forEach(file => excludedfilesArr.push(file.id));
            let excludedfilestring = excludedfilesArr.join("|");

            $.ajax({
                url: '/secure/pacrs/pacr-form/pacr-form?handler=UploadFiles',
                method: "POST",
                //dataType: "json",
                cache: false,
                contentType: false,
                processData: false,
                data: formData,
                //files: formData,
                headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val(), "FormId": this.CurrentForm.Id.toString(), "ExcludedFiles": excludedfilestring },
                success: (response) => {
                    //console.log(response);
                    this.FileUpload.shouldUpdateFiles = false;
                    isFileUploadComplete = true;

                    if (isFormSaveComplete) {
                        $("#pacrModal").find(".modal-body").html(bodyContentAfter);
                        $("#pacrModal").find(".modal-footer").html(footerContentAfter);
                        $("#pacrModal").find(".modal-footer").removeClass("d-none");
                        $("#pacrModal").find(".close").removeClass("d-none");

                        // redirect to submission page
                        let path = window.location.pathname.split("/");
                        if (path.length >= 5) {
                            window.location.href = "../form-submission";
                        }
                        else {
                            window.location.href = "../pacrs/form-submission";
                        }
                    }
                },
                error: (response) => {
                    console.log("error", response);
                    $("#pacrModal").find(".modal-body").html(errorContentAfter);
                    $("#pacrModal").find(".modal-footer").removeClass("d-none");
                    alert("An unexpected error occurred. Please try again.");
                }
            });
        }
        else {
            isFileUploadComplete = true;
        }
        

        $.ajax({
            url: '/secure/pacrs/pacr-form/pacr-form?handler=SaveForm',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(pacrFormData), contactData: JSON.stringify(contactData), dataSources: JSON.stringify(dataSources) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                //console.log(response);
                isFormSaveComplete = true;

                if (isFileUploadComplete) {
                    $("#pacrModal").find(".modal-body").html(bodyContentAfter);
                    $("#pacrModal").find(".modal-footer").html(footerContentAfter);
                    $("#pacrModal").find(".modal-footer").removeClass("d-none");
                    $("#pacrModal").find(".close").removeClass("d-none");

                    // redirect to submission page
                    let path = window.location.pathname.split("/");
                    if (path.length >= 5) {
                        window.location.href = "../form-submission";
                    }
                    else {
                        window.location.href = "../pacrs/form-submission";
                    } 
                }
                       
            },
            error: (response) => {
                //console.log("error", response);
                $("#pacrModal").find(".modal-body").html(errorContentAfter);
                $("#pacrModal").find(".modal-footer").removeClass("d-none");
                alert("An unexpected error occurred. Please try again.");
            }
        });
    }

    /*nextSectionSave(): void {
        // Disable The Save Button
        $(".pacr-save-btn").addClass("inactive")

        // Show Saving Text
        $("#save-progress-text").html(`<i class="fa-solid fa-spinner spin "></i><span class="pl-2">Saving...</span>`);
        $("#save-progress-text").removeClass("invisible");

        // Save Form Content
        let formObj = this.createFormObj();
        let contactObj = this.createContactObj();
        let dataSourceObj = this.createDataSourceObj();

        let isFileUploadComplete = false;
        let isFormSaveComplete = false;

        // Handle File Uploads 
        if (this.FileUpload.shouldUpdateFiles) {
            let formData: FormData = new FormData();

            let files: File[] = this.FileUpload.files;

            for (var i = 0; i < files.length; i++) {
                let key: string = this.CurrentForm.Id.toString() + "/" + files[i].name;
                formData.append(key, files[i]);
            }

            let excludedfilesArr = [];
            this.FileUpload.uploadedFiles.forEach(file => excludedfilesArr.push(file.id));
            let excludedfilestring = excludedfilesArr.join("|");

            $.ajax({
                url: '/secure/pacrs/pacr-form/pacr-form?handler=UploadFiles',
                method: "POST",
                cache: false,
                contentType: false,
                processData: false,
                data: formData,
                headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val(), "FormId": this.CurrentForm.Id.toString(), "ExcludedFiles": excludedfilestring },
                success: (response) => {
                    //console.log(response);
                    this.FileUpload.shouldUpdateFiles = false;
                    isFileUploadComplete = true;

                    if (isFormSaveComplete) {
                        // Show Saved Text
                        $("#save-progress-text").html(`<span>Saved!</span>`);

                        // Re-enable Save Button
                        $(".pacr-save-btn").removeClass("inactive");

                        setTimeout(() => {
                            $("#save-progress-text").addClass("invisible");
                        }, 1000);
                    }
                },
                error: (response) => {
                    console.log("error", response);
                    alert("An unexpected error occurred while saving. Please try again.");
                }
            });
        }
        else {
            isFileUploadComplete = true;
        }


        // Handle Form Save
        $.ajax({
            url: '/secure/pacrs/pacr-form/pacr-form?handler=SaveForm',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(formObj), contactData: JSON.stringify(contactObj), dataSources: JSON.stringify(dataSourceObj) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                //console.log(response);
                isFormSaveComplete = true;

                if (isFileUploadComplete) {
                    // Show Saved Text
                    $("#save-progress-text").html(`<span>Saved!</span>`);

                    // Re-enable Save Button
                    $(".pacr-save-btn").removeClass("inactive");

                    setTimeout(() => {
                        $("#save-progress-text").addClass("invisible");
                    }, 1000);
                }
            },
            error: (response) => {
                console.log("error", response);
                alert("An unexpected error occurred while saving. Please try again.");
            }
        }); 
    }*/
   
}
