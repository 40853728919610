export class HrrnContact {
    FirstName: string;
    LastName: string;
    Email: string;
    Affiliation: string;
    Institution: string;

    FullName: string;
    DropdownDisplay: string;

    initialize() {
        this.FullName = `${this.FirstName} ${this.LastName}`;
        this.DropdownDisplay = `${this.LastName}, ${this.FirstName}`;
        this.updateAffiliation();
    }

    constructor(firstName: string, lastName: string, email: string, affiliation: string, institution: string) {
        this.FirstName = firstName;
        this.LastName = lastName; 
        this.Email = email;
        this.Affiliation = affiliation;
        this.Institution = institution;
        this.initialize();
    }

    updateAffiliation(): void {
        if (this.Affiliation == "A Network-Based, Mixed Methods Study to Identify and Support Multiple Overdose Responders and Inform Overdose Prevention Interventions") {
            this.Affiliation = "A Network-Based, Mixed Methods Study to Identify and Support Multiple Overdose Responders and Inform Overdose Prevention Interventions (Project H.E.R.O.)";
        }
        if (this.Affiliation == "Assessing the Reach, Effectiveness, and Implementation of Multiple Harm Reduction Interventions") {
            this.Affiliation = "Assessing the Reach, Effectiveness, and Implementation of Multiple Harm Reduction Interventions (SF Moon Study)";
        }
        if (this.Affiliation == "Mobile Health Strategies to Support Longitudinal Engagement in Harm Reduction Services") {
            this.Affiliation = "Mobile Health Strategies to Support Longitudinal Engagement in Harm Reduction Services (ENHANCE)";
        }
        if (this.Affiliation == "A Comparative Evaluation of Overdose Prevention Programs in New York City and Rhode Island") {
            this.Affiliation = "A Comparative Evaluation of Overdose Prevention Programs in New York City and Rhode Island (SAFER Study)";
        }
        if (this.Affiliation == "Expansion of Mail-Delivered Harm Reduction Services in the U.S.") {
            this.Affiliation = "Harm Reduction Services Offered Through Mail-Delivery Expansion (HOME)";
        }
    }
} 

export class HrrnResearchProject {
    Name: string;
    PIs: HrrnContact[];

    constructor(name: string, associatedPIs: HrrnContact[]) {
        this.Name = name;
        this.PIs = associatedPIs;
    }
}

export class HrrnDataSource {
    Id: number;
    Name: string;
    SourceType: string;
    IsShowOnPortal: boolean;
    DropdownDisplay: string;

    initiialize() {
        this.DropdownDisplay = `${this.Name} (${this.SourceType})`;
    }

    constructor(name: string, sourceType: string) {
        this.Name = name;
        this.SourceType = sourceType;
        this.initiialize();
    }
}

export class HrrnFile {
    id: string;
    icon: string;
    link: string;
    text: string;
}

export class PacrDataSource {
    Id: number;
    PacrFormId: number;
    SourceType: string;
    Name: string;

    constructor(formId: number, name: string, sourceType: string) {
        this.Id = 0;
        this.PacrFormId = formId;
        this.Name = name;
        this.SourceType = sourceType;
    }
}

export class PacrFormObj {
    Id: number = 0;
    PacrFormId: string;
    Status: string;
    CreateDate?: Date; 
    LastUpdateDate: Date;
    SubmissionDate?: Date;

    SubmitterFirstName: string;
    SubmitterLastName: string;
    SubmitterEmail: string;

    ProposalType: string;
    Title: string;

    LeadFirstName: string;
    LeadLastName: string;
    LeadEmail: string;
    LeadPhone: string;
    LeadResearchProject: HrrnResearchProject;
    AssociatedPIs: HrrnContact[];

    CoInvestigators: HrrnContact[];
    AssociatedRPs: HrrnResearchProject[];

    ProposalSummary: string;
    ProposalSpecificAims: string;
    PrimarySecondaryOutcomes: string;
    DataSources: HrrnDataSource[];
    ResearchMethodology: string;
    DataHandling: string;
    IrbRequirements: string;
    ExpectedTimeline: string;

    CCSupport: string;
    UploadedFiles: File[];
    Comments: string;

    AssociatedResearchProjects: string;
    LeadResearchProjectName: string;

    constructor() {

    }
}

export class PacrContact {
    Id: number;
    PacrFormId: number;
    ContactType: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Affiliation: string;
    Institution: string;

    constructor(formId: number, contactType: string, contactInfo: HrrnContact) {
        this.Id = 0;
        this.PacrFormId = formId;
        this.ContactType = contactType;
        this.FirstName = contactInfo?.FirstName;
        this.LastName = contactInfo?.LastName;
        this.Email = contactInfo?.Email;
        this.Affiliation = contactInfo?.Affiliation;
        this.Institution = contactInfo?.Institution;
    }
}

