import { plainToClass } from 'class-transformer';
import { Legend } from 'highcharts';

var Highcharts = require('highcharts/highcharts');
require('highcharts/modules/sankey')(Highcharts);
require('highcharts/modules/dependency-wheel')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

export class DependencyWheel {
    connectionsArray: any[];
    chartId: string;
    wheelChart: any;
    legendId: string;
    rpFullNameArray: string[];
    rpShortNameArray: string[];
    colorArray: string[];

    constructor(id: string, connections: any[], callbackFn = () => { }) {

        //document.ready
        $(() => {
            this.connectionsArray = [];
            this.chartId = id;
            this.legendId = "research-project-wheel-legend";

            this.rpFullNameArray = [
                "A Comparative Evaluation of Overdose Prevention Programs in New York City and Rhode Island (SAFER Study)",//0
                "A Network-Based, Mixed Methods Study to Identify and Support Multiple Overdose Responders and Inform Overdose Prevention Interventions (Project H.E.R.O.)", //1
                "Assessing the Reach, Effectiveness, and Implementation of Multiple Harm Reduction Interventions (SF Moon Study)", //2
                "Undoing Harm and Integrating Services (Project Unite)", //3
                "Harm Reduction Services Offered Through Mail-Delivery Expansion (HOME)", //4
                "Implementing and Evaluating the Impact of Novel Mobile Harm Reduction Services on Overdose Among Women Who Use Drugs (SHOUT)", //5
                "Mobile Health Strategies to Support Longitudinal Engagement in Harm Reduction Services (ENHANCE)", //6
                "Peers Expanding Engagement in Methamphetamine Harm-Reduction with Contingency Management (PEER-CM)", //7
                "Promoting Remote Harm Reduction and Secondary Services in Rural Settings (PROMOTE)", //8
                "Teaching Harm Reduction In Vulnerable Environments (THRIVE)", //9
                "Coordination Center", //10
                "NIH/NIDA" //11
            ];

            this.rpShortNameArray = [
                "SAFER Study (NYU/Brown)", //0
                "Project H.E.R.O. (UNR)", //1
                "SF Moon Study (RTI)", //2
                "Project Unite (NYU)", //3
                "HOME (Cornell)", //4
                "SHOUT (JHU)", //5
                "ENHANCE (UWM/Tulane)", //6
                "PEER-CM (OHSU)", //7
                "PROMOTE (UC/UIC)", //8
                "THRIVE (Penn)", //9,
                "HRRN-CC", //10
                "NIH" //11
            ];

            this.colorArray = [
                '#058DC7', //0
                '#50B432', //1
                '#ED561B', //2
                '#DDDF00', //3
                '#24CBE5', //4
                '#64E572', //5
                '#FF9655', //6
                '#FFF263', //7
                '#6AF9C4', //8
                '#C25594', //9
                '#9E46C0', //10
                '#C43B60' // 11
            ];

            // Map RP names for data
            for (let i = 0; i < connections.length; i++) {
                let from = connections[i][0];
                let to = connections[i][1];
                let weight = connections[i][2];

                if (!from || !to) continue;

                if (this.rpFullNameArray.includes(from)) {
                    let index = this.rpFullNameArray.indexOf(from);
                    from = this.rpShortNameArray[index];
                }
                if (this.rpFullNameArray.includes(to)) {
                    let index = this.rpFullNameArray.indexOf(to);
                    to = this.rpShortNameArray[index];
                }

                this.connectionsArray.push([from, to, weight, undefined]);
            }


            this.initializeChart();

            // Create Legend Options
            let legendData: LegendData[] = [];
            for (let i = 0; i < this.rpShortNameArray.length; i++) {
                let fromCount = this.connectionsArray.filter(connection => connection[0] == this.rpShortNameArray[i] && connection[2] > 0).length;
                let toCount = this.connectionsArray.filter(connection => connection[1] == this.rpShortNameArray[i] && connection[2] > 0).length;
                let count = fromCount + toCount;
                let legendItem = new LegendData(this.rpShortNameArray[i], this.rpFullNameArray[i], count, this.colorArray[i]);
                legendData.push(legendItem);
            }

            this.initializeWheelLegend(legendData);

            callbackFn();
        });

    }

    private initializeChart(): void {        

        let nodeArray = [];
        for (let i = 0; i < this.rpShortNameArray.length; i++) {
            nodeArray.push({
                id: this.rpShortNameArray[i],
                color: this.colorArray[i],
                colorIndex: i
            })
        }


        Highcharts.setOptions({
            colors: this.colorArray
        });

        this.wheelChart = Highcharts.chart(this.chartId, {

            chart: {
                height: '100%',
                animation: false, // issue with globalAnimation
            },

            title: {
                text: ''
            },

            accessibility: {
                point: {
                    valueDescriptionFormat: '{index}. From {point.from} to {point.to}: {point.weight}.'
                }
            },

            plotOptions: {
                dependencywheel: {
                    tooltip: {
                        pointFormat: "{point.fromNode.name} ↔ {point.toNode.name}: <b>{point.weight}</b><br/>"
                    },
                    events: {
                        mouseOver: () => {
                            this.resetLegendSelect();
                        },
                    }
                }               
            },

            series: [{
                keys: ['from', 'to', 'weight', 'colorIndex'],
                data: this.connectionsArray.filter(connection => connection[2] != 0),                
                type: 'dependencywheel',
                name: 'Connection',
                dataLabels: {
                    color: '#333',
                    style: {
                        textOutline: 'none',
                    },
                },
                size: "95%",
                nodes: nodeArray,
                animation: false, // issue with gloablAnimation
            }],
          
           
        });
     
        // Sets Colors of the Links
        let data = this.wheelChart.series[0].data;
        for (let j = 0; j < data.length; j++) {
            data[j].colorIndex = undefined;
            let foundIndex = this.rpShortNameArray.findIndex(name => name == data[j].from);
            if (foundIndex >= 0) {
                data[j].color = this.colorArray[foundIndex];
            }
        }

        this.wheelChart.series[0].setData(data);
        //console.log(this.wheelChart);
    }

    private hoverForCategory(category: string): void {
        let points = this.wheelChart.series[0].points;

        // Deactivate All Points
        this.setStateAllPoints("inactive");
        for (let i = 0; i < points.length; i++) {
            // Skip 0 weight items
            if (points[i].weight == 0) continue;

            // Set Relevant Points to Hover
            if (points[i].from == category || points[i].to == category) {
                points[i].setState("hover");
            }
        }
    }

    private setStateAllPoints(state: string): void {
        let points = this.wheelChart.series[0].points;
        for (let i = 0; i < points.length; i++) {
            points[i].setState(state);
        }
    }


    private initializeWheelLegend(legendData: LegendData[]): void {
        $(".wheel-legend").find('.legend-column-one').empty();

        for (let i = 0; i < legendData.length; i++) {

            let content =
                `<div class="legend-item d-flex" id="${legendData[i].name}">
                    <div>
                        <i class="fa-solid fa-circle pr-2" style="color: ${legendData[i].colorString}"></i>
                    </div>
                    <div>
                        <span class="legend-name pr-1">${legendData[i].displayName}</span>
                        <span class="legend-connections">(${legendData[i].connections})</span>
                    </div>
                </div>`

            $(".wheel-legend").find(".legend-column-one").append(content);
        }

        let chart = this;

        $(".legend-item").on("click", function () {
            let id = $(this).attr('id');           
            chart.setStateAllPoints("");
            let shouldSelect = !$(this).hasClass("selected");
            $(".legend-item").removeClass("selected");
            if (shouldSelect) {
                chart.hoverForCategory(id);
                $(this).addClass("selected");
            }         
        });

        
    }

    private resetLegendSelect(): void {
        $(".legend-item").removeClass("selected");
    }

}

class LegendData {
    name: string;
    displayName: string;
    connections: number;
    colorString: string;

    constructor(name: string, displayName: string, connections: number, colorString: string) {
        this.name = name;
        this.connections = connections;
        this.colorString = colorString;
        this.displayName = displayName;
    }
}
