import { InitializeFilterDropdown, GetSelectedDropdownValues, ResetDropdownFilters, IFilter } from '../../../../shared/scripts/filtering';
import { Unique } from '../../../../shared/scripts/array-functions';
import { HrrnContact, HrrnFile } from './pacr-form.entities';
import { plainToClass } from 'class-transformer';
export class FileUpload {

    uploadBlock: JQuery<HTMLElement>;
    uploadGallery: JQuery<HTMLElement>;
    uploadInput: JQuery<HTMLElement>;

    files: File[];
    uploadedFiles: HrrnFile[];

    shouldUpdateFiles: boolean;

    constructor(uploadBlockId: string, callbackFn: Function = () => { }) {
        //document.ready
        $(() => {
            this.uploadBlock = $(uploadBlockId);
            this.uploadGallery = this.uploadBlock.find(".file-upload-gallery").first();
            this.uploadInput = this.uploadBlock.find(".file-upload-input").first();

            this.files = [];
            this.uploadedFiles = [];
            this.shouldUpdateFiles = false;

            this.preventDefaultBehavior();
            this.highlightUploadBlock();
            this.fileDrop();
            this.chooseFileDrop();

            callbackFn();
        });
    }

    private preventDefaultBehavior() {
        // disable default behavior for upload blocks
        this.uploadBlock.on("dragenter dragover dragleave drop", (e) => {
            e.preventDefault();
            e.stopPropagation();
        });

        // disable default behavior for page
        $("body").on("dragenter dragover dragleave drop", (e) => {
            e.preventDefault();
            e.stopPropagation();
        });

    }

    private highlightUploadBlock() {
        this.uploadBlock.on("dragenter dragover", (e) => {
            this.uploadBlock.addClass("highlight-upload-block");
        })

        this.uploadBlock.on("dragleave drop", (e) => {
            this.uploadBlock.removeClass("highlight-upload-block");
        })
    }

    private fileDrop() {
        
        this.uploadBlock.on("drop", (e) => {

            let uploadedFiles = e.originalEvent.dataTransfer.files;

            if (uploadedFiles.length <= 0) {
                return;
            }

            for (let i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i])
            }
         
            this.drawGallery();
        })
    }

    private chooseFileDrop() {
        
        this.uploadInput.on("change", (e) => {
            let uploadedFiles: File[] = this.uploadInput.prop("files");

            if (uploadedFiles.length <= 0) {
                return;
            }

            for (let i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i])
            }

            this.drawGallery();
        
        })
    }

    public drawGallery() {
        // Set Update Files
        this.shouldUpdateFiles = true;

        // Empty the gallery
        this.uploadGallery.empty();

        // Create Table in the Gallery for each file
        let galleryContent = "";

        // Add Content for Each Previously Uploaded File
        for (let i = 0; i < this.uploadedFiles.length; i++) {
            let icon = this.getHrrnFileIcon(this.uploadedFiles[i]);

            galleryContent +=
                `<div class="card">
                    <div class="card-header" id="file-heading-${i}">
                        <div class="row justify-content-between">
                            <div class="col-auto">
                                <div class="header-text">
                                    <a class="text-decoration-none d-flex align-items-center">
                                        ${icon}<span class="expand-text d-block pl-2">${this.uploadedFiles[i].text}</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-auto">
                                <a id="delete-uploaded-file-btn-${i}" class="text-decoration-none header-delete-uploaded-btn">
                                    <i class="fa-solid fa-trash"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>`
        }

        // Add Content for Each File
        for (let i = 0; i < this.files.length; i++) {
            let icon = this.getFileIcon(this.files[i].type);

            galleryContent +=
                `<div class="card">
                    <div class="card-header" id="file-heading-${i}">
                        <div class="row justify-content-between">
                            <div class="col-auto">
                                <div class="header-text">
                                    <a class="text-decoration-none d-flex align-items-center">
                                        ${icon}<span class="expand-text d-block pl-2">${this.files[i].name}</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-auto">
                                <a id="delete-file-btn-${i}" class="text-decoration-none header-delete-btn">
                                    <i class="fa-solid fa-trash"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>`
        }

        // Draw Table
        this.uploadGallery.append(galleryContent);

            
        // Add button functionality for deleting elements
        let fileUploadObj = this;

        this.uploadGallery.find(".header-delete-btn").on("click", function () {
            let fileId: string = $(this).prop("id");
            let index: number = parseInt(fileId.replace("delete-file-btn-", ""));
            fileUploadObj.files.splice(index, 1);
            fileUploadObj.drawGallery();
        });

        this.uploadGallery.find(".header-delete-uploaded-btn").on("click", function () {
            let fileId: string = $(this).prop("id");
            let index: number = parseInt(fileId.replace("delete-uploaded-file-btn-", ""));
            fileUploadObj.uploadedFiles.splice(index, 1);
            fileUploadObj.drawGallery();
        })
    }

    private getFileIcon(fileType: string) {

        if (fileType.includes("image")) return `<i class="fas fa-file-image expand-btn"></i>`;
        if (fileType.includes("pdf")) return `<i class="fa-solid fa-file-pdf expand-btn"></i>`;
        if (fileType.includes("word")) return `<i class="fa-solid fa-file-word expand-btn"></i>`;
        if (fileType.includes("spreadsheet")) return `<i class="fa-solid fa-file-excel expand-btn"></i>`;
        if (fileType.includes("presentation")) return `<i class="fa-solid fa-file-powerpoint expand-btn"></i>`;
        if (fileType.includes("audio")) return `<i class="fa-solid fa-file-audio expand-btn"></i>`;
        if (fileType.includes("video")) return `<i class="fa-solid fa-file-video expand-btn"></i>`;

        return `<i class="fa-solid fa-file expand-btn"></i>`
    }

    private getHrrnFileIcon(file: HrrnFile) {
        return `<i class="${file.icon} expand-btn"></i>`
    }
  
}
