import { HRRNEvent } from './events.entities';
import { plainToClass } from 'class-transformer';

export class EventsPage {
    eventArray: HRRNEvent[];
    dataTable: any;

    constructor(dataJson: any) {

        //document.ready
        $(() => {
            this.eventArray = plainToClass(HRRNEvent, <HRRNEvent[]>dataJson);
            this.eventArray.sort((x1: HRRNEvent, x2: HRRNEvent) => x1.SortOrder - x2.SortOrder);
            this.eventArray.map((data: HRRNEvent) => {
                data.Initialize();
                return data;
            });

            //console.log(this.eventArray);

            this.initializeTable();
            this.setEventsFoundLabel(this.eventArray.length);
        });

    }

    initializeTable(): void {
        this.dataTable = $('#events-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls"<"reset-section">Bf>rtip',
            autoWidth: false,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            //scrollY: '70vh',
            orderCellsTop: true,
            language:
            {
                search: "Search:",
                /*buttons: {
                    copyTitle: 'Lab Assays Data Copied',
                    copyKeys: 'Use your keyboard or menu to select the copy command'
                }*/
            },
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '24px' }, //0
                { data: "EventName", className: "text-left font-size12 pl-0", width: '28%' }, //1
                { data: "EventType", className: "text-left font-size12", width: '20%' }, //2
                { data: "Date", className: "text-left font-size12", width: '12%' }, //3
                { data: "Time", className: "text-left font-size12" }, //4
                { data: "LinkHTML", className: "text-left font-size12", width: '8%' }, //5
            ],
            buttons: [
                {
                extend: 'csv',
                text: '<i class="fas fa-file-download"></i>',
                titleAttr: 'CSV',
                charset: 'utf-8',
                //exportOptions: {
                //    columns: [1, 2, 3, 4]
                //}
                }
            ],
            data: this.eventArray,
            initComplete: () => {
                $("#events-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        //on td.details-control removed
        $('#events-table tbody').on('click', 'tr', (event) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(event.currentTarget);
            var row = this.dataTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                if (row.data() != undefined) {
                    // Open this row
                    row.child(this.createDetailRow(row.data())).show();
                    tr.addClass('shown');
                }
            }
        });

        $(".top-controls").addClass('row mx-0 flex-row-reverse');
        $("#events-table_filter").addClass("mr-auto");
        $("#events-table_filter").after(`<div id="event-type-dropdown" class="mr-3"></div>`);
        $("#event-type-dropdown").append($("#filter-dropdown"));
        $(".dt-buttons").before(`<span class="align-self-end pl-1 table-message" id="events-found-count"></span>`);
        $(".dt-buttons").addClass("align-self-end mb-2");




        $('#events-table').on('search.dt', (e, settings) => {
            this.setEventsFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

        this.initializeFilters();
    }

    initializeFilters(): void {

        for (let index = 2; index <= 2; ++index) {

            let uniqueValues: string[] = [];
            let delimiter: string = "";

            let filterIndex: number = index;
            let dropdownSourceIndex: number = index;

            //if (index == 5) filterIndex = 7;

            this.dataTable.column(dropdownSourceIndex).data().unique().map((data: string) => {
                let parts: string[] = [];
                if (delimiter != "") { parts = data.split(delimiter); }
                else { parts[0] = data; }

                parts.map((part: string) => {
                    part = part.trim();
                    if (part.length > 0 && !uniqueValues.includes(part)) {
                        uniqueValues.push(part);
                    }
                });
            });

            uniqueValues = uniqueValues.sort((a: string, b: string) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });

            let selectId: string = '#filter-dropdown-' + index;

            $(selectId).selectpicker({
                dropupAuto: false,
                selectedTextFormat: "count",

                countSelectedText: (numSelected, numTotal) => {
                    if (numSelected == numTotal) {
                        return "All";
                    }
                    else {
                        return numSelected + " Selected";
                    }
                }
            });

            uniqueValues.forEach((element: any) => {
                $(selectId).append(new Option(element, element, false, true));
            });
            $(selectId).selectpicker("refresh");


            $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
                let values: string[] = <string[]>($(selectId).val());
                //console.log("changed", filterIndex, values);

                let search: string = "";
                if (values.length > 0) {
                    search = "(\\b" + values.join("\\b)|(\\b") + "\\b)";
                }
                else { search = "NOTHING-MATCHED-1234567890"; }

                search = search.split("(").join("").split(")").join("");

                this.dataTable.column(filterIndex)
                    .search(search, true, false)
                    .draw();
            });
        }
    }

    createDetailRow(data: HRRNEvent): string {

        let html: string = "<div class='d-block text-left px-4'>" + data.Description + "</div>"
        return html;
    }

    setEventsFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#events-found-count').text("No Events Found"); break;
            case 1: $('#events-found-count').text("1 Event Found"); break;
            default: $('#events-found-count').text(count + " Events Found");
        }
    }
}
