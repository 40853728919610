
// SCRIPTS

import { SecureHomePage } from './home/home.page'; export { SecureHomePage };
import { UserDirectoryPage } from './communications/user-directory/user-directory.page'; export { UserDirectoryPage };
import { EventsPage } from './communications/events/scripts/events.page'; export { EventsPage };
import { PacrForm } from './pacrs/pacr-form/scripts/pacr-form.page'; export { PacrForm }
import { PacrDashboardPage } from './pacrs/pacr-dashboard/scripts/pacr-dashboard.page'; export { PacrDashboardPage };
import { PacrReportingPage } from './pacrs/reporting/scripts/pacr-reporting.page'; export { PacrReportingPage };
import { PublicationsPage } from './publications/scripts/publications.page'; export { PublicationsPage };
//import { SinglePage } from './single-page/scripts/single.page'; export { SinglePage }; //to make discoverable from cshtml
//import { ContactUsPage } from './contact-us/contact-us.page'; export { ContactUsPage };
//import { FundingPage } from './funding/funding.page'; export { FundingPage };
//import { NewsEventsPage } from './news-events/news-events.page'; export { NewsEventsPage };
////import { StudiesPage } from './studies/scripts/studies.page'; export { StudiesPage };
//import { AboutPage } from './about/about.page'; export { AboutPage };
//import { FaqsPage } from './faqs/faqs.page'; export { FaqsPage };
//import { ResearchPage } from './research/research.page'; export { ResearchPage };
//import { WhoWeArePage } from './who-we-are/who-we-are.page'; export { WhoWeArePage };
//import { ResearchWorksPage } from './research-works/research-works.page'; export { ResearchWorksPage };
//import { CommunityPage } from './community/community.page'; export { CommunityPage };
//import { LongCovidPage } from './long-covid/long-covid.page'; export { LongCovidPage };

// STYLES
import './home/home.scss';
import './research-projects/research-projects.scss';
import './communications/user-directory/user-directory.scss';
import './committees/committee-page.scss';
import './communications/events/events.scss';
import './communications/digests-form/digests-form.scss';
import './pacrs/pacr-form/pacr-form.scss';
import './pacrs/pacr-dashboard/pacr-dashboard.scss';
import './pacrs/reporting/pacr-reporting.scss';
import './publications/publications.scss';
import './resources/resources.scss';
//import './contact-us/contact-us.scss';
//import './funding/funding.scss';
//import './news-events/news-events.scss';
//import './about/about.scss';
//import './faqs/faqs.scss';
//import './research/research.scss';
//import './who-we-are/who-we-are.scss';
//import './research-works/research-works.scss';
//import './community/community.scss';
//import './long-covid/long-covid.scss';

