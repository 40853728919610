import { plainToClass } from 'class-transformer';
import { Legend } from 'highcharts';
import { HrrnContact } from '../../pacr-form/scripts/pacr-form.entities'

var Highcharts = require('highcharts/highcharts');
require('highcharts/modules/networkgraph')(Highcharts);


Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

export class NetworkGraph {

    networkConnections: any[]
    chartId: string;
    networkGraph: any;
    rpFullNameArray: string[];
    colorArray: string[];
    hrrnContacts: HrrnContact[];

    constructor(id: string, connections: any[], hrrnContacts: HrrnContact[], callbackFn = () => { }) {

        //document.ready
        $(() => {
            this.chartId = id;
            this.networkConnections = connections;
            this.hrrnContacts = hrrnContacts;

            this.rpFullNameArray = [
                "A Comparative Evaluation of Overdose Prevention Programs in New York City and Rhode Island (SAFER Study)",//0
                "A Network-Based, Mixed Methods Study to Identify and Support Multiple Overdose Responders and Inform Overdose Prevention Interventions (Project H.E.R.O.)", //1
                "Assessing the Reach, Effectiveness, and Implementation of Multiple Harm Reduction Interventions (SF Moon Study)", //2
                "Undoing Harm and Integrating Services (Project Unite)", //3
                "Harm Reduction Services Offered Through Mail-Delivery Expansion (HOME)", //4
                "Implementing and Evaluating the Impact of Novel Mobile Harm Reduction Services on Overdose Among Women Who Use Drugs (SHOUT)", //5
                "Mobile Health Strategies to Support Longitudinal Engagement in Harm Reduction Services (ENHANCE)", //6
                "Peers Expanding Engagement in Methamphetamine Harm-Reduction with Contingency Management (PEER-CM)", //7
                "Promoting Remote Harm Reduction and Secondary Services in Rural Settings (PROMOTE)", //8
                "Teaching Harm Reduction In Vulnerable Environments (THRIVE)", //9
                "Coordination Center", //10
                "NIH/NIDA" //11
            ];

            this.colorArray = [
                '#058DC7', // 0
                '#50B432', // 1
                '#ED561B', // 2
                '#DDDF00', // 3
                '#24CBE5', // 4
                '#64E572', // 5
                '#FF9655', // 6
                '#FFF263', // 7
                '#6AF9C4', // 8
                '#C25594', // 9
                '#9E46C0', // 10
                '#C43B60' // 11
            ];
          

            this.initializeChart();

            // Create Legend Options
            let legendData: LegendData[] = [];
            for (let i = 0; i < this.rpFullNameArray.length; i++) {
                legendData.push(new LegendData(this.rpFullNameArray[i], this.colorArray[i], i))
            }
            legendData.push(new LegendData("No Associated HRRN Research Project", "#A9A9A9", 12));

            this.initializeNetworkLegend(legendData);

            callbackFn();
        });

    }

    private initializeChart(): void {
        
        Highcharts.setOptions({
            colors: this.colorArray
        });
      
        this.networkGraph = Highcharts.chart(this.chartId, {
            chart: {
                type: 'networkgraph',
                height: '100%',
            },
            title: {
                text: '',
            },
            plotOptions: {
                networkgraph: {
                    keys: ['from', 'to'],
                    layoutAlgorithm: {
                        enableSimulation: true,
                        gravitationalConstant: 0,
                        initialPositions: "random",
                        attractiveForce: function () {
                            return 0
                        },
                        repulsiveForce: function () {
                            return 0
                        },
                    },
                    events: {
                        mouseOver: () => {
                            this.resetLegendSelect();
                        },
                    }
                }
            },
            series: [{
                dataLabels: {
                    enabled: true,
                    linkFormat: '',
                    style: {
                        fontSize: '0.8em',
                        fontWeight: 'normal'
                    }
                },
                id: 'pacrs-network-graph',
                data: this.networkConnections,
            }]
        });

        // Custom Settings for Chart
        if (this.networkGraph.series[0].nodes) {


            // Sets Colors of Nodes
            for (let j = 0; j < this.networkGraph.series[0].nodes.length; j++) {
                this.networkGraph.series[0].nodes[j].colorIndex = undefined;
                if (this.networkGraph.series[0].nodes[j]) {

                    // Look for FirstName / LastName in HrrnContacts
                    let foundContact = this.hrrnContacts.find(contact => contact.FullName == this.networkGraph.series[0].nodes[j].id);
                    if (foundContact) {
                        let foundProjectIndex = this.rpFullNameArray.indexOf(foundContact.Affiliation);
                        if (foundProjectIndex != -1) {
                            this.networkGraph.series[0].nodes[j].color = this.colorArray[foundProjectIndex]
                            this.networkGraph.series[0].nodes[j].colorIndex = foundProjectIndex;
                            this.networkGraph.series[0].nodes[j].projectName = foundContact.Affiliation;
                        }
                        else {
                            this.networkGraph.series[0].nodes[j].color = "#A9A9A9";
                            this.networkGraph.series[0].nodes[j].colorIndex = 12;
                            this.networkGraph.series[0].nodes[j].projectName = "No Associated HRRN Research Project";
                        }
                    }
                    else {
                        this.networkGraph.series[0].nodes[j].color = "#A9A9A9";
                        this.networkGraph.series[0].nodes[j].colorIndex = 12;
                        this.networkGraph.series[0].nodes[j].projectName = "No Associated HRRN Research Project";
                    }            
                }
            }
         
            this.networkGraph.redraw();

        }

        //console.log(this.networkGraph)
    }

    private initializeNetworkLegend(legendData: LegendData[]): void {
        let chart = this;

        $(".network-legend").find('.legend-column-one').empty();

        for (let i = 0; i < legendData.length; i++) {

            let content =
                `<div class="network-legend-item d-flex" id="${legendData[i].name}">
                    <div>
                        <i class="fa-solid fa-circle pr-2" style="color: ${legendData[i].color}"></i>
                    </div>
                    <div>
                        <span class="legend-name">${legendData[i].name}</span>
                    </div>
                </div>`

            $(".network-legend").find(".legend-column-one").append(content);
        }

        $(".network-legend-item").on("click", function () {
            // check if it is selected
            if ($(this).hasClass("selected")) {
                // Set State for All Nodes to ""
                for (let i = 0; i < chart.networkGraph.series[0].nodes.length; i++) {
                    chart.networkGraph.series[0].nodes[i].setState("");
                }
                $(this).removeClass("selected");
            }
            else {
                let rpName = $(this).attr('id'); // Name of RP

                let matchedNodes = [];
                if (chart.networkGraph.series[0].nodes) {
                    // Create a list of nodes that match the selected RP
                    matchedNodes = chart.networkGraph.series[0].nodes.filter(node => node.projectName == rpName);

                    // Set State for All Nodes to ""
                    for (let i = 0; i < chart.networkGraph.series[0].nodes.length; i++) {
                        chart.networkGraph.series[0].nodes[i].setState("inactive");
                    }

                    // Set State for Matched Nodes to Hover
                    for (let i = 0; i < chart.networkGraph.series[0].nodes.length; i++) {
                        if (matchedNodes.includes(chart.networkGraph.series[0].nodes[i])) {
                            chart.networkGraph.series[0].nodes[i].setState("hover");
                        }
                    }

                }

                $(".network-legend-item").removeClass("selected");
                $(this).addClass("selected");
            }

            
        });
    }

    resetLegendSelect() {
        $(".network-legend-item").removeClass("selected");
    }

}

class LegendData {
    name: string;
    color: string;
    colorIndex: number;

    constructor(name: string, color: string, colorIndex: number) {
        this.name = name;
        this.color = color;
        this.colorIndex = colorIndex;
    }
}
