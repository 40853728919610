import { InitializeFilterDropdown, GetSelectedDropdownValues, ResetDropdownFilters, IFilter } from '../../../../shared/scripts/filtering';
import { Unique } from '../../../../shared/scripts/array-functions';
import { HrrnContact, HrrnResearchProject } from './pacr-form.entities';
import { plainToClass } from 'class-transformer';
import { PacrTable } from './pacr-table';
export class ContactSelect implements IFilter {

    contactList: HrrnContact[] = [];
    researchProjectList: HrrnResearchProject[] = [];
    contactSelectId: string;

    contactBlock: JQuery<HTMLElement>;
    firstNameInput: JQuery<HTMLElement>;
    lastNameInput: JQuery<HTMLElement>;
    emailInput: JQuery<HTMLElement>;
    organizationInput: JQuery<HTMLElement>;
    affiliationInput: JQuery<HTMLElement>;
    affiliationInputOptions: string[] = [];

    associatedRPTable: PacrTable;

    addedContacts: HrrnContact[] = [];
    contactTableId: string = "#contacts-collapse-table";

    constructor(contactSelectId: string, contactList: HrrnContact[], researchProjectList: HrrnResearchProject[], callbackFn: Function = () => { }) {
        //document.ready
        $(() => {

            this.contactList = contactList;
            this.researchProjectList = researchProjectList;
            this.contactSelectId = contactSelectId;
            this.contactBlock = $(this.contactSelectId).closest(".contact-selection-block");

            this.initializeContactDropdown();

            this.firstNameInput = this.contactBlock.find(".first-name-input");
            this.lastNameInput = this.contactBlock.find(".last-name-input");
            this.emailInput = this.contactBlock.find(".email-input");
            this.affiliationInput = this.contactBlock.find(".pacr-contact-dropdown");

            let options = [];
            this.affiliationInput.find("option").each(function () {
                options.push($(this).prop("value"));
            });
            this.affiliationInputOptions = options;
            this.organizationInput = this.contactBlock.find(".institution-input");

            this.addedContacts = [];

            this.initializeAddBtn();
            this.initializeAssociatedRPTable();

            // optionally run a callback function after the selector is generated
            callbackFn();
        });
    }

    initializeContactDropdown(): void {
        InitializeFilterDropdown(this.contactSelectId, [], this, this.contactList.map((contact: HrrnContact) => [contact.DropdownDisplay, contact.Email]).sort((a: any[], b: any[]) => a[0] - b[0]), false);

        /*let contactBlock = $(this.contactSelectId).closest(".contact-selection-block");*/
        this.contactBlock.find(".reset-contact-search-btn").click(() => {
            $(this.contactSelectId).val("").trigger("change");
        })

    }

    initializeAddBtn(): void {
        /*let contactBlock = $(this.contactSelectId).closest(".contact-selection-block");*/
        let addBtn = this.contactBlock.find(".add-contact-btn");

        addBtn.click(() => {
            let isValid = this.contactValidation();
            if (isValid) {

                // Create New Contact
                let addedContact: HrrnContact = this.getAddedContact();

                // Add New Contact to Existing List
                this.addedContacts.push(addedContact);

                // Add New Contact to Table
                this.drawTable();

                // Reset Fields
                $(this.contactSelectId).val("").trigger("change");
            }
        })

    }

    contactValidation(): boolean {
        this.resetValidation();
        let isValid = true;

        if (this.firstNameInput.val() == "" || this.lastNameInput.val() == "" || this.emailInput.val() == "" || this.affiliationInput.val() == "" || this.organizationInput.val() == "") {
            isValid = false;

            let affiliationDropdown = this.contactBlock.find(".pacr-contact-dropdown .dropdown-toggle")

            this.firstNameInput.val() == "" ? this.firstNameInput.addClass("is-invalid") : this.firstNameInput.addClass("is-valid");
            this.lastNameInput.val() == "" ? this.lastNameInput.addClass("is-invalid") : this.lastNameInput.addClass("is-valid");
            this.emailInput.val() == "" ? this.emailInput.addClass("is-invalid") : this.emailInput.addClass("is-valid");
            this.affiliationInput.val() == "" ? affiliationDropdown.addClass("is-invalid") : affiliationDropdown.addClass("is-valid");
            this.organizationInput.val() == "" ? this.organizationInput.addClass("is-invalid") : this.organizationInput.addClass("is-valid");
            this.contactBlock.find(".contact-invalid-feedback").removeClass("d-none");
        }

        return isValid;

    }

    resetValidation(): void {
        let affiliationDropdown = this.contactBlock.find(".pacr-contact-dropdown .dropdown-toggle");

        this.firstNameInput.removeClass("is-valid"); this.firstNameInput.removeClass("is-invalid");
        this.lastNameInput.removeClass("is-valid"); this.lastNameInput.removeClass("is-invalid");
        this.emailInput.removeClass("is-valid"); this.emailInput.removeClass("is-invalid");
        affiliationDropdown.removeClass("is-valid"); affiliationDropdown.removeClass("is-invalid");
        this.organizationInput.removeClass("is-valid"); this.organizationInput.removeClass("is-invalid");
        this.contactBlock.find(".contact-invalid-feedback").addClass("d-none");
    }

    // Runs when the item is changed in the dropdown
    triggerFilter(): void {
        let searchValue = $(this.contactSelectId).val();

        if (searchValue == "" || searchValue == undefined) {
            this.resetValues();          
        }
        else {
            let foundContact = this.contactList.filter(contact => contact.Email == searchValue);

            if (foundContact.length < 1) {
                this.resetValues();
            }
            else {
                //$(this.contactSelectId).val("").trigger("change");
                this.setValues(foundContact[0]);
            }
        }

    }

    resetValues(): void {
        this.resetValidation();

        this.firstNameInput.val("");
        this.lastNameInput.val("");
        this.emailInput.val("");
        this.affiliationInput.val("").trigger("change");
        this.organizationInput.val("");
    }

    setValues(contact: HrrnContact): void {
        this.resetValidation();

        this.firstNameInput.val(contact.FirstName);
        this.lastNameInput.val(contact.LastName);
        this.emailInput.val(contact.Email);
        this.organizationInput.val(contact.Institution);
        if (!this.affiliationInputOptions.includes(contact.Affiliation)) {
            this.affiliationInput.val("No Associated HRRN Research Project").trigger("change");
        }
        else {
            this.affiliationInput.val(contact.Affiliation).trigger("change");
        }
    }

    getAddedContact(): HrrnContact {
        let addedContact: HrrnContact = new HrrnContact(
            this.firstNameInput.val().toString(),
            this.lastNameInput.val().toString(),
            this.emailInput.val().toString(),
            this.affiliationInput.val().toString(),
            this.organizationInput.val().toString()
        );

        return addedContact;
    }

    deleteContact(index: number): void {
        this.addedContacts.splice(index, 1);
        this.drawTable();
    }

    drawTable(): void {
        // Empty Current Table
        let contactTable = this.contactBlock.find(".hrrn-collapse-table");
        contactTable.empty();
        let tableContent = "";

        // Set Added Count/Text
        if (this.addedContacts.length == 0) {
            contactTable.addClass("d-none");
            this.contactBlock.find(".no-table-items-text").removeClass("d-none").addClass("d-block");
            this.contactBlock.find(".added-contacts-count").text("");
        }
        else {
            contactTable.removeClass("d-none");
            this.contactBlock.find(".no-table-items-text").addClass("d-none").removeClass("d-block");
            this.contactBlock.find(".added-contacts-count").text(this.addedContacts.length);
        }

        // Add Table Rows
        for (let i = this.addedContacts.length - 1; i >= 0; i--) {
            let rowContent = `
                <div class="card">
                    <div class="card-header" id="contact-heading-${i}">
                        <div class="row justify-content-between">
                            <div class="col-auto">
                                <div class="header-text">
                                    <a class="collapsed text-decoration-none d-flex align-items-center" data-toggle="collapse" data-target="#contact-collapse-${i}" aria-expanded="false" aria-controls="contact-collapse-${i}">
                                        <i class="fa fa-plus-circle expand-btn" aria-hidden="true"></i><span class="expand-text d-block pl-2">${this.addedContacts[i].FullName}</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-auto">
                                <a id="delete-contact-btn-${i}" class="text-decoration-none header-delete-btn">
                                    <i class="fa-solid fa-trash"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="contact-collapse-${i}" class="collapse" aria-labelledby="contact-heading-${i}" data-parent="${this.contactTableId}">
                        <div class="card-body p-0">
                            <table class="table pacr-table mb-0">  
                                <tbody>
                                    <tr>
                                        <td class="table-row-label" style="width: 105px">Name: </td>
                                        <td>${this.addedContacts[i].FullName}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Email: </td>
                                        <td>${this.addedContacts[i].Email}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Research Project: </td>
                                        <td>${this.addedContacts[i].Affiliation}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Organization: </td>
                                        <td>${this.addedContacts[i].Institution}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>`;

            tableContent += rowContent;
        }

        // Add to Table
        contactTable.append(tableContent);
        let contactSelect = this;

        // Set Functionality for newly generated delete buttons
        this.contactBlock.find(".header-delete-btn").click(function () {
            let butnId: string = $(this).attr("id");
            let index: number = parseInt(butnId.replace("delete-contact-btn-", ""));
            contactSelect.deleteContact(index);
        });

        // Custom functionality for show/hide items ($(this) = card-body)
        this.contactBlock.find(".collapse").on("show.bs.collapse", function () {
            $(this).siblings(".card-header").addClass("active");
        });

        this.contactBlock.find(".collapse").on("hide.bs.collapse", function () {
            $(this).siblings(".card-header").removeClass("active");
        });

        // Update the Associated RP Table
        this.drawAssociatedRPTable();
    }

    initializeAssociatedRPTable(): void {
        this.associatedRPTable = new PacrTable("#associated-research-projects-table", "research-project", () => {
            // Event listener for table
            $("#lead-research-project").on("change", () => {
                let projectName = $("#lead-research-project").val();
                let researchProject = this.researchProjectList.filter(project => project.Name == projectName)[0];

                if (researchProject) {
                    this.associatedRPTable.leadResearchProject = researchProject;
                }
                else {
                    this.associatedRPTable.leadResearchProject = null;
                }

                this.drawAssociatedRPTable();
            })

            $("#lead-research-project").trigger("change");
        });
        
    }

    drawAssociatedRPTable(): void {
        // Get unique RP Names
        let researchProjectNames = Unique(this.addedContacts.map((contact) => contact.Affiliation), true);
        let addedResearchProjects: HrrnResearchProject[] = [];

        // Get each RP object from name and add to array
        researchProjectNames.forEach((name) => {
            let foundResearchProject = this.researchProjectList.filter((project) => project.Name == name);
            if (foundResearchProject.length > 0) {
                addedResearchProjects.push(foundResearchProject[0]);
            }
        });

        // Redraw Table
        this.associatedRPTable.updateData(addedResearchProjects);
    }
    

}
