import { UserExperience } from "../../../auth/pages/profile/scripts/expertise.objects";
import { FormatStringByWordLength, DoArraysOverlap } from "../../../shared/scripts/array-functions";

export class UserDirectoryEntity {

    FirstName: string;
    LastName: string;
    ProjectRole: string;
    Email: string;
    Expertise: UserExperience;
    Institution: string = "";
    Country: string = "";
    Phone: string = "";
    LinkToProfessionalBio: string;
    Affiliation: string;

    DisplayName: string;
    SortName: string;
    DisplayEmail: string = "";
    DisplayBio: string = "";
    DisplayPhone: string = "";
    DisplayCountry: string = "";
    DisplayAffiliation: string = "";
    DisplayInstitution: string = "";
    DisplayRole: string = "";

    ExpertiseGeneral: string = "";
    ExpertiseAnalysis: string = "";
    ExpertiseHarmReduction: string = "";
    ExpertisePopulation: string = "";

    HiddenFilter: string = "";

    constructor() { }

    initialize(): void {

        this.DisplayName = this.FirstName + " " + this.LastName;
        this.DisplayName = FormatStringByWordLength(this.DisplayName, 4);
        this.SortName = this.LastName + " " + this.FirstName;

        if (this.Affiliation == "SAG") { this.Affiliation = "Scientific Advisory Group (SAG)"; }

        if (this.Email?.length > 0) {
            this.DisplayEmail = "<a href='mailto:" + this.Email + "'><span class='nowrap'>" + this.Email.toLowerCase() + "<i class='fa fa-envelope link-icon' aria-hidden='true'></i></span></a>";
        }

        if (this.LinkToProfessionalBio?.length > 0) {
            this.DisplayBio = "<a href='" + this.LinkToProfessionalBio + "' target='_blank'><span class='nowrap'>Bio<i class='fa fa-external-link-alt link-icon' aria-hidden='true'></i></span></a>";
        }

        if (this.Phone?.length > 0) {
            this.Phone = "<span class='nowrap'>" + this.Phone + "</span>";
        }
        if (this.Country?.length > 0) {
            this.DisplayCountry = "<span class='nowrap'>" + this.Country + "</span>";
        }

        this.DisplayAffiliation = FormatStringByWordLength(this.Affiliation, 4);
        this.DisplayInstitution = FormatStringByWordLength(this.Institution, 4);
        this.ProjectRole = FormatStringByWordLength(this.ProjectRole, 4);

        if (this.Expertise) {
            this.ExpertiseGeneral = FormatStringByWordLength(this.Expertise.getGeneralExperienceString(), 4);
            this.ExpertiseAnalysis = FormatStringByWordLength(this.Expertise.getAnalysisExperienceString(), 4);
            this.ExpertiseHarmReduction = FormatStringByWordLength(this.Expertise.getHarmReductionExperienceString(), 4);
            this.ExpertisePopulation = FormatStringByWordLength(this.Expertise.getPopulationExperienceString(), 4);

        }
    }

    //matchesFilter(affiliation: any, institute: any, country: any, basicScienceExpertise: any, diagnosticExpertise: any, helminthExpertise: any, protozoanExpertise: any, bacteriaExpertise: any): boolean {
    matchesFilter(affiliation: any, institute: any, generalExpertise: any, analysisExpertise: any, harmReductionExpertise: any, populationExpertise: any): boolean {

        //console.log(affiliation, this.Affiliation);

        if (!DoArraysOverlap(affiliation, this.Affiliation)) { return false; }
        if (!DoArraysOverlap(institute, this.Institution)) { return false; }
        //if (!DoArraysOverlap(country, this.Country)) { return false; }
        if (generalExpertise.length > 0 && !DoArraysOverlap(generalExpertise, this.Expertise?.GeneralExperience)) { return false; }
        if (analysisExpertise.length > 0 && !DoArraysOverlap(analysisExpertise, this.Expertise?.AnalysisExperience)) { return false; }
        if (harmReductionExpertise.length > 0 && !DoArraysOverlap(harmReductionExpertise, this.Expertise?.HarmReductionExperience)) { return false; }
        if (populationExpertise.length > 0 && !DoArraysOverlap(populationExpertise, this.Expertise?.PopulationExperience)) { return false; }

        return true;
    }
}