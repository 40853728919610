



export class TableColumnVisibilityController {

    tableId: string;
    columnGroups: TableColumnGroup[];

    constructor(tableId: string, dropdownDivId: string, columnGroups: TableColumnGroup[]) {
        this.tableId = tableId;
        this.columnGroups = columnGroups;

        $(dropdownDivId).empty().html("<div class=\"row mx-0 w-100\"><label for=\"column-filter-dropdown\" class=\"col-form-label filter-label py-0\">Columns to View</label><select id=\"column-filter-dropdown\" class=\"selectpicker dropdown w-100\" title=\"None Selected\" data-actions-box=\"true\" data-live-search=\"false\" data-container=\"body\" multiple></select></div>");

        $('#column-filter-dropdown').selectpicker({
            dropupAuto: false,
            selectedTextFormat: "count",

            countSelectedText: (numSelected, numTotal) => {
                if (numSelected == numTotal) {
                    return "All Available";
                }
                else {
                    return numSelected + " Selected";
                }
            }
        });
        this.columnGroups.forEach((group: TableColumnGroup) => {
            $('#column-filter-dropdown').append(new Option(group.name, group.name, false, group.visible));
        });
        $('#column-filter-dropdown').selectpicker("refresh");


        $('#column-filter-dropdown').on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            this.updateColumnVisibility();
        });
        this.updateColumnVisibility();

    }

    refresh(): void {
        $('#column-filter-dropdown').selectpicker("refresh");
    }

    updateColumnVisibility(): void {

        let values: string[] = <string[]>($('#column-filter-dropdown').val());

        let visibleColumns: number[] = [];
        let visibleStyles: string[] = [];
        let hiddenColumns: number[] = [];
        let hiddenStyles: string[] = [];
        this.columnGroups.forEach((colgroup: TableColumnGroup) => {
            if (values.includes(colgroup.name)) {
                visibleColumns.push(...colgroup.columns);
                if (colgroup.columnStyle.length > 0) { visibleStyles.push(colgroup.columnStyle); }
            }
            else {
                hiddenColumns.push(...colgroup.columns);
                if (colgroup.columnStyle.length > 0) { hiddenStyles.push(colgroup.columnStyle); }
            }
        });

        var table = $(this.tableId).DataTable();
        if (hiddenColumns.length > 0) { table.columns(hiddenColumns).visible(false, false); }
        if (visibleColumns.length > 0) { table.columns(visibleColumns).visible(true, false); }

        hiddenStyles.forEach((style: string) => {
            table.columns('.' + style).visible(false, false);
        });
        visibleStyles.forEach((style: string) => {
            table.columns('.' + style).visible(true, false);
        });

        table.columns.adjust().draw(false); // adjust column sizing and redraw

    }
}


export class TableColumnGroup {
    name: string;
    visible: boolean;
    columns: number[];
    columnStyle: string;

    constructor(name: string, visible: boolean, columns: number[], columnStyle: string) {
        this.name = name;
        this.visible = visible;
        this.columns = columns;
        this.columnStyle = columnStyle;
    }
}