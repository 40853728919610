import { InitializeFilterDropdown, GetSelectedDropdownValues, ResetDropdownFilters, IFilter } from '../../../../shared/scripts/filtering';
import { Unique } from '../../../../shared/scripts/array-functions';
import { HrrnContact, HrrnResearchProject, HrrnDataSource } from './pacr-form.entities';
import { plainToClass } from 'class-transformer';
import { PacrTable } from './pacr-table';
import { add } from 'lodash';
export class DataSourceSelect implements IFilter {

    dataSourceList: HrrnDataSource[] = [];
    dataSourceSelectId: string;

    dataSourceBlock: JQuery<HTMLElement>;

    addedDataSources: HrrnDataSource[] = [];
    dataSourceTableId: string = "#data-source-collapse-table";

    constructor(dataSourceSelectId: string, dataSourceList: HrrnDataSource[], callbackFn: Function = () => { }) {
        //document.ready
        $(() => {

            this.dataSourceList = dataSourceList;
            this.dataSourceSelectId = dataSourceSelectId;
            this.dataSourceBlock = $(this.dataSourceSelectId).closest(".data-source-selection-block");

            this.initializeDataSourceDropdown();

            this.addedDataSources = [];

            this.initializeAddBtn();

            // optionally run a callback function after the selector is generated
            callbackFn();
        });
    }

    initializeDataSourceDropdown(): void {
        InitializeFilterDropdown(this.dataSourceSelectId, [], this, this.dataSourceList.map((dataSource: HrrnDataSource) => [dataSource.DropdownDisplay, dataSource.Id]).sort((a: any[], b: any[]) => a[0] - b[0]), false);

        this.dataSourceBlock.find(".reset-data-source-search-btn").click(() => {
            $(this.dataSourceSelectId).val("").trigger("change");
        })

    }

    initializeAddBtn(): void {
        /*let contactBlock = $(this.contactSelectId).closest(".contact-selection-block");*/
        let addBtn = this.dataSourceBlock.find(".add-data-source-btn");

        addBtn.click(() => {
            let isValid = this.dataSourceValidation();
            if (isValid) {

                // Create New Contact
                let addedDataSource: HrrnDataSource = this.getAddedDataSource();

                // Add New Contact to Existing List
                this.addedDataSources.push(addedDataSource);

                // Add New Contact to Table
                this.drawTable();

                // Reset Fields
                $(this.dataSourceSelectId).val("").trigger("change");
            }
        })

    }

    dataSourceValidation(): boolean {
        let isValid = true;

        let foundDataSource: HrrnDataSource = this.getAddedDataSource();
        if (!foundDataSource) {
            isValid = false;
        }

        return isValid;

    }

    // Runs when the item is changed in the dropdown
    triggerFilter(): void {
        let searchValue = $(this.dataSourceSelectId).val();
        let addBtn = this.dataSourceBlock.find(".add-data-source-btn");

        if (searchValue == "" || searchValue == undefined) {
            // Hide Add Data Source Button
            addBtn.addClass("d-none");
        }
        else {
            // Show Add Data Source Button
            addBtn.removeClass("d-none");
        }

    }

    getAddedDataSource(): HrrnDataSource {
        let addedDataSource: HrrnDataSource[] = this.dataSourceList.filter(dataSource => dataSource.Id == $(this.dataSourceSelectId).val());
        if (addedDataSource.length != 0) {
            return addedDataSource[0]
        }
        else {
            return null;
        }
    }

    deleteContact(index: number): void {
        this.addedDataSources.splice(index, 1);
        this.drawTable();
    }

    drawTable(): void {
        // Empty Current Table
        let dataSourceTable = this.dataSourceBlock.find(".hrrn-collapse-table");
        dataSourceTable.empty();
        let tableContent = "";

        // Set Added Count/Text
        if (this.addedDataSources.length == 0) {
            dataSourceTable.addClass("d-none");
            this.dataSourceBlock.find(".no-table-items-text").removeClass("d-none").addClass("d-block");
            this.dataSourceBlock.find(".added-data-source-count").text("");
        }
        else {
            dataSourceTable.removeClass("d-none");
            this.dataSourceBlock.find(".no-table-items-text").addClass("d-none").removeClass("d-block");
            this.dataSourceBlock.find(".added-data-source-count").text(this.addedDataSources.length);
        }

        // Add Table Rows
        for (let i = this.addedDataSources.length - 1; i >= 0; i--) {
            let rowContent = `
                <div class="card">
                    <div class="card-header" id="data-source-heading-${i}">
                        <div class="row justify-content-between">
                            <div class="col-auto">
                                <div class="header-text">
                                    <a class="collapsed text-decoration-none d-flex align-items-center" data-toggle="collapse" data-target="#data-source-collapse-${i}" aria-expanded="false" aria-controls="data-source-collapse-${i}">
                                        <i class="fa fa-plus-circle expand-btn" aria-hidden="true"></i><span class="expand-text d-block pl-2">${this.addedDataSources[i].DropdownDisplay}</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-auto">
                                <a id="delete-data-source-btn-${i}" class="text-decoration-none header-delete-btn">
                                    <i class="fa-solid fa-trash"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="data-source-collapse-${i}" class="collapse" aria-labelledby="data-source-heading-${i}" data-parent="${this.dataSourceTableId}">
                        <div class="card-body p-0">
                            <table class="table pacr-table mb-0">  
                                <tbody>
                                    <tr>
                                        <td class="table-row-label" style="width: 125px">Data Source: </td>
                                        <td>${this.addedDataSources[i].Name}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Source Type: </td>
                                        <td>${this.addedDataSources[i].SourceType}</td>
                                    </tr>                         
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>`;

            tableContent += rowContent;
        }

        // Add to Table
        dataSourceTable.append(tableContent);
        let contactSelect = this;

        // Set Functionality for newly generated delete buttons
        this.dataSourceBlock.find(".header-delete-btn").click(function () {
            let butnId: string = $(this).attr("id");
            let index: number = parseInt(butnId.replace("delete-data-source-btn-", ""));
            contactSelect.deleteContact(index);
        });

        // Custom functionality for show/hide items ($(this) = card-body)
        this.dataSourceBlock.find(".collapse").on("show.bs.collapse", function () {
            $(this).siblings(".card-header").addClass("active");
        });

        this.dataSourceBlock.find(".collapse").on("hide.bs.collapse", function () {
            $(this).siblings(".card-header").removeClass("active");
        });

    }   
}
