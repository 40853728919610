export class HRRNEvent {

    Id: number;
    SortOrder: number;
    EventName: string;
    Date: string;
    Time: string;
    EventType: string;
    Description: string;
    EventLink: string;
    ShowOnPortal: string;

    LinkHTML: string = "";
    IsShowOnPortal: boolean;

    Initialize(): void {
        if (this.EventLink != null && this.EventLink != "") {
            this.LinkHTML = `<a href="${this.EventLink}" target="_blank"><span class="nowrap">Details<i class="fas fa-external-link-alt px-1"></i></span></a>`;
        }
    }
} 