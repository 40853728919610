import { plainToClass } from 'class-transformer';
import { DependencyWheel } from '../scripts/dependency-wheel';
import { NetworkGraph } from '../scripts/network-graph';
import { SiteMetricsChart } from '../scripts/site-metrics-chart';
import { HrrnContact } from '../../pacr-form/scripts/pacr-form.entities';
export class PacrReportingPage {
    reserachProjectConnections: any[];
    networkConnections: any[];
    dependencyWheel: DependencyWheel;
    networkGraph: NetworkGraph;
    siteMetricsChart: SiteMetricsChart;
    hrrnContacts: HrrnContact[];
    singleSiteMetricsArray: any[];

    constructor(ResearchProjectConnections: any, NetworkConnections: any, HrrnContactsJson: any, SingleSiteMetrics: any) {

        //document.ready
        $(() => {
            this.reserachProjectConnections = ResearchProjectConnections;
            this.networkConnections = NetworkConnections;
            this.singleSiteMetricsArray = SingleSiteMetrics;

            this.hrrnContacts = plainToClass(HrrnContact, <HrrnContact[]>HrrnContactsJson);
            this.hrrnContacts.sort((x1: HrrnContact, x2: HrrnContact) => x1.LastName.localeCompare(x2.LastName));
            this.hrrnContacts.map((data: HrrnContact) => {
                data.initialize();
                return data;
            });

            //console.log(this.reserachProjectConnections);
            //console.log(this.networkConnections);
            //console.log(this.singleSiteMetricsArray);

            this.dependencyWheel = new DependencyWheel("research-project-wheel-container", this.reserachProjectConnections, () => {
                this.showLoadedContent();
            });
            this.networkGraph = new NetworkGraph("network-graph-container", this.networkConnections, this.hrrnContacts);
            this.siteMetricsChart = new SiteMetricsChart("single-site-metrics-chart-container", this.singleSiteMetricsArray);

        });

    }

    private showLoadedContent() : void {
        $("#loading-wheel").addClass("d-none");
        $("#pacr-tabs-container").removeClass("d-none");
        $("#pacr-reporting-tab-content").removeClass("d-none");
    }

    public showHelpModal(): void {
        $("#pacr-help-modal").modal("show");
    }
}
