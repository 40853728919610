


export class UserExperienceEntry {
    Email: string;
    ExperienceJson: string;
    constructor() { }
}

export class UserExperience {

    ExpertiseAreas: ExpertiseArea[] = undefined;

    GeneralExperience: string[];
    AnalysisExperience: string[];
    HarmReductionExperience: string[];
    PopulationExperience: string[];

    constructor() {}

    initialize(): void {

        $("#expertise-form :input").each((index, element) => {
            let input: any = $(element);
            let inputId: string = input.attr("id");
            //console.log(input.attr("id"), input.is(":checked"));

            if (input.is(":checked")) {

                if (inputId.startsWith("general")) {
                    if (!this.GeneralExperience) this.GeneralExperience = [];
                    this.GeneralExperience.push(inputId);
                }
                else if (inputId.startsWith("analysis")) {
                    if (!this.AnalysisExperience) this.AnalysisExperience = [];
                    this.AnalysisExperience.push(inputId);
                }
                else if (inputId.startsWith("harm-reduction")) {
                    if (!this.HarmReductionExperience) this.HarmReductionExperience = [];
                    this.HarmReductionExperience.push(inputId);
                }
                else if (inputId.startsWith("population")) {
                    if (!this.PopulationExperience) this.PopulationExperience = [];
                    this.PopulationExperience.push(inputId);
                }

                //console.log(input.attr("id"));
            }
        });
    }

    setupUI(): void {

        //console.log(this);

        let switches: string[] = ["GeneralExperience", "AnalysisExperience", "HarmReductionExperience", "PopulationExperience"];
        switches.forEach((field: string) => {
            if (this[field] != undefined) {
                this[field].forEach((switchId: string) => {
                    $("#" + switchId).prop("checked", true);
                });
            }
        });
    }

    getGeneralExperienceString(): string {
        let output: string = "";
        if (this.GeneralExperience == undefined && this.GeneralExperience == undefined) return "";
        if (this.GeneralExperience != undefined) {

            if (this.ExpertiseAreas == undefined) { this.ExpertiseAreas = ExpertiseArea.GetExpertiseAreas(); }

            this.ExpertiseAreas.forEach((area: ExpertiseArea) => {
                if (this.GeneralExperience.includes(area.Id)) output = this.addValue(output, area.Expertise);
            });
        }
        return output;
    }

    getAnalysisExperienceString(): string {
        let output: string = "";
        if (this.AnalysisExperience == undefined && this.AnalysisExperience == undefined) return "";
        if (this.AnalysisExperience != undefined) {

            if (this.ExpertiseAreas == undefined) { this.ExpertiseAreas = ExpertiseArea.GetExpertiseAreas(); }

            this.ExpertiseAreas.forEach((area: ExpertiseArea) => {
                if (this.AnalysisExperience.includes(area.Id)) output = this.addValue(output, area.Expertise);
            });
        }
        return output;
    }

    getHarmReductionExperienceString(): string {
        let output: string = "";
        if (this.HarmReductionExperience == undefined && this.HarmReductionExperience == undefined) return "";
        if (this.HarmReductionExperience != undefined) {

            if (this.ExpertiseAreas == undefined) { this.ExpertiseAreas = ExpertiseArea.GetExpertiseAreas(); }

            this.ExpertiseAreas.forEach((area: ExpertiseArea) => {
                if (this.HarmReductionExperience.includes(area.Id)) output = this.addValue(output, area.Expertise);
            });
        }
        return output;
    }

    getPopulationExperienceString(): string {
        let output: string = "";
        if (this.PopulationExperience == undefined && this.PopulationExperience == undefined) return "";
        if (this.PopulationExperience != undefined) {

            if (this.ExpertiseAreas == undefined) { this.ExpertiseAreas = ExpertiseArea.GetExpertiseAreas(); }

            this.ExpertiseAreas.forEach((area: ExpertiseArea) => {
                if (this.PopulationExperience.includes(area.Id)) output = this.addValue(output, area.Expertise);
            });
        }
        return output;
    }

    addValue(output: string, value: string): string {
        if (output.length > 0) { output += ", "; }
        output += value;
        return output;
    }

    getHiddenFilter(): string {
        let output: string = "";
        output = this.getGeneralExperienceString() + " " + this.getAnalysisExperienceString() + " " + this.getHarmReductionExperienceString() + " " + this.getPopulationExperienceString();
        return output;
    }
}

export class ExpertiseArea {

    constructor(readonly Id: string, readonly Area: string, readonly Expertise: string) { }

    static GetExpertiseAreas(): ExpertiseArea[] {
        let areas: ExpertiseArea[] = [];

        areas.push(new ExpertiseArea("general1", "", "Addiction Medicine"));
        areas.push(new ExpertiseArea("general2", "", "Addiction Prevention and Treatment Research"));
        areas.push(new ExpertiseArea("general3", "", "Biostatistics"));
        areas.push(new ExpertiseArea("general4", "", "Communicable Disease"));
        areas.push(new ExpertiseArea("general5", "", "Community-based Participatory Research"));
        areas.push(new ExpertiseArea("general6", "", "Contingency Management"));
        areas.push(new ExpertiseArea("general7", "", "Data Coordination"));
        areas.push(new ExpertiseArea("general8", "", "Economic Evaluation"));
        areas.push(new ExpertiseArea("general9", "", "Epidemiology"));
        areas.push(new ExpertiseArea("general10", "", "Health Disparities"));
        areas.push(new ExpertiseArea("general11", "", "Health Services"));
        areas.push(new ExpertiseArea("general12", "", "HIV"));
        areas.push(new ExpertiseArea("general13", "", "Implemetation Science"));
        areas.push(new ExpertiseArea("general14", "", "Infectious Disease Epidemiology"));
        areas.push(new ExpertiseArea("general15", "", "Intervention Development"));
        areas.push(new ExpertiseArea("general16", "", "PrEP"));
        areas.push(new ExpertiseArea("general17", "", "STIs"));
        areas.push(new ExpertiseArea("general18", "", "SUD Treatment"));
        areas.push(new ExpertiseArea("general19", "", "Surveillance Systems"));

        areas.push(new ExpertiseArea("analysis1", "", "Biostatistics/Stastistics"));
        areas.push(new ExpertiseArea("analysis2", "", "Ethnographic or Qualitative Research"));
        areas.push(new ExpertiseArea("analysis3", "", "Respondent Driven Sampling"));
        areas.push(new ExpertiseArea("analysis4", "", "Social Network Analysis"));
        areas.push(new ExpertiseArea("analysis5", "", "Statistical Computing"));

        areas.push(new ExpertiseArea("harm-reduction1", "", "Clinical Care for PWUD"));
        areas.push(new ExpertiseArea("harm-reduction2", "", "Communicable Disease Harm Reduction"));
        areas.push(new ExpertiseArea("harm-reduction3", "", "Design and Analysis of Harm Reduction Studies"));
        areas.push(new ExpertiseArea("harm-reduction4", "", "Drug Use Epidemiology"));
        areas.push(new ExpertiseArea("harm-reduction5", "", "Dynamic Mathematical Modeling to predict drug use epidemic-related health harms including OD, and assess intervention impact"));
        areas.push(new ExpertiseArea("harm-reduction6", "", "Environmental and structural vulnerabilities that drive violence"));
        areas.push(new ExpertiseArea("harm-reduction7", "", "Evaluation of Harm Reduction Services"));
        areas.push(new ExpertiseArea("harm-reduction8", "", "Harm Reduction Interventions/Programming"));
        areas.push(new ExpertiseArea("harm-reduction9", "", "MOUD/MOUD Linkage"));
        areas.push(new ExpertiseArea("harm-reduction10", "", "OEND"));
        areas.push(new ExpertiseArea("harm-reduction11", "", "Social Networks among PWUD"));
        areas.push(new ExpertiseArea("harm-reduction12", "", "SUD Treatment"));

        areas.push(new ExpertiseArea("population1", "", "Female Sex Workers"));
        areas.push(new ExpertiseArea("population2", "", "Incarcerated Persons with Substance Use Disorder"));
        areas.push(new ExpertiseArea("population3", "", "PWUD"));
        areas.push(new ExpertiseArea("population4", "", "Rural Settings"));
        areas.push(new ExpertiseArea("population5", "", "Women Who Use Drugs"));

        return areas;
    }
}