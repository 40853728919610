import { PacrFormObj, PacrContact, PacrDataSource, HrrnFile } from '../../pacr-form/scripts/pacr-form.entities'
import { FormatDate } from '../../../../shared/scripts/date-functions';
import { DoArraysOverlap } from '../../../../shared/scripts/array-functions';

export class PacrDashboardItem {
    Form: PacrFormObj;
    Contacts: PacrContact[];
    DataSources: PacrDataSource[];
    Comments: PacrComment[];
    Files: HrrnFile[];

    // Display Fields
    DisplayID: string;
    DisplayLeadResearcher: string;
    DisplayInvolvedResearchCenters: string;
    DisplayContacts: string;
    DisplayDataSources: string;
    DisplayLastUpdatedDate: string;
    DisplaySubmitDate: string;

    // Dropdown Filter Fields
    DropdownContacts: string[];
    DropdownResearchProjects: string[];
    DropdownDataSources: string[];

    // Status History
    StatusHistoryComments: StatusHistory[];

    initialize() {
        let rpFullNameArray = [
            "A Comparative Evaluation of Overdose Prevention Programs in New York City and Rhode Island (SAFER Study)",//0
            "A Network-Based, Mixed Methods Study to Identify and Support Multiple Overdose Responders and Inform Overdose Prevention Interventions (Project H.E.R.O.)", //1
            "Assessing the Reach, Effectiveness, and Implementation of Multiple Harm Reduction Interventions (SF Moon Study)", //2
            "Undoing Harm and Integrating Services (Project Unite)", //3
            "Harm Reduction Services Offered Through Mail-Delivery Expansion (HOME)", //4
            "Implementing and Evaluating the Impact of Novel Mobile Harm Reduction Services on Overdose Among Women Who Use Drugs (SHOUT)", //5
            "Mobile Health Strategies to Support Longitudinal Engagement in Harm Reduction Services (ENHANCE)", //6
            "Peers Expanding Engagement in Methamphetamine Harm-Reduction with Contingency Management (PEER-CM)", //7
            "Promoting Remote Harm Reduction and Secondary Services in Rural Settings (PROMOTE)", //8
            "Teaching Harm Reduction In Vulnerable Environments (THRIVE)", //9
            "Coordination Center", //10
            "NIH/NIDA" //11
        ];

        let rpShortNameArray = [
            "SAFER Study (NYU/Brown)", //0
            "Project H.E.R.O. (UNR)", //1
            "SF Moon Study (RTI)", //2
            "Project Unite (NYU)", //3
            "HOME (Cornell)", //4
            "SHOUT (JHU)", //5
            "ENHANCE (UWM/Tulane)", //6
            "PEER-CM (OHSU)", //7
            "PROMOTE (UC/UIC)", //8
            "THRIVE (Penn)", //9,
            "HRRN-CC", //10
            "NIH" //11
        ];

        this.DisplayID = `<a class="view-details-link">${this.Form.PacrFormId}</a>`;

        this.DisplayLeadResearcher = "";
        if (this.Form.LeadFirstName && this.Form.LeadLastName) {
            this.DisplayLeadResearcher = `${this.Form.LeadFirstName} ${this.Form.LeadLastName}`;
        }    

        this.DisplayInvolvedResearchCenters = "";
        if (this.Form.AssociatedResearchProjects) {
            let associatedResearchProjectsArr = this.Form.AssociatedResearchProjects.split("|");
            for (let i = 0; i < associatedResearchProjectsArr.length; i++) {

                // Convert Long Name to Short Name
                let rpFullName = associatedResearchProjectsArr[i];
                let rpDisplayName = rpFullName;
                let foundIndex = rpFullNameArray.findIndex(name => name == associatedResearchProjectsArr[i]);

                if (foundIndex >= 0) {
                    rpDisplayName = rpShortNameArray[foundIndex];
                }

                if (i > 0) {
                    this.DisplayInvolvedResearchCenters += `<br/>`;
                }
                this.DisplayInvolvedResearchCenters += rpDisplayName;
            }
        }
        
        this.DisplayContacts = "";
        for (let i = 0; i < this.Contacts.length; i++) {
            if (i > 0) {
                this.DisplayContacts += `<br/>`; 
            }
            this.DisplayContacts += `${this.Contacts[i].FirstName} ${this.Contacts[i].LastName} (${this.Contacts[i].ContactType})`;
        }

        this.DisplayDataSources = "";
        for (let i = 0; i < this.DataSources.length; i++) {
            if (i > 0) {
                this.DisplayDataSources += `<br/>`;
            }
            this.DisplayDataSources += `${this.DataSources[i].Name} (${this.DataSources[i].SourceType})`
        }

        this.DisplaySubmitDate = "";
        if (this.Form.SubmissionDate) {
            this.DisplaySubmitDate = FormatDate(this.Form.SubmissionDate);
        }

        this.DisplayLastUpdatedDate = "";
        if (this.Form.LastUpdateDate) {
            this.DisplayLastUpdatedDate = FormatDate(this.Form.LastUpdateDate);
        }

        this.DropdownContacts = [];
        if (this.Contacts) {
            for (let i = 0; i < this.Contacts.length; i++) {
                if (this.Contacts[i].FirstName != null && this.Contacts[i].FirstName != "" && this.Contacts[i].LastName != null && this.Contacts[i].LastName != "")
                    this.DropdownContacts.push(`${this.Contacts[i].LastName}, ${this.Contacts[i].FirstName}`);
            }
        }
        if (this.Form.LeadFirstName && this.Form.LeadLastName) {
            this.DropdownContacts.push(`${this.Form.LeadLastName}, ${this.Form.LeadFirstName}`);
        }
        

        this.DropdownResearchProjects = [];
        if (this.Form.AssociatedResearchProjects) {
            this.DropdownResearchProjects = this.Form.AssociatedResearchProjects.split("|");           
        }
        if (this.DropdownResearchProjects.length == 0) {
            this.DropdownResearchProjects.push("-- No Associated HRRN Research Project --");
        }
        

        this.DropdownDataSources = [];
        if (this.DataSources) {
            for (let i = 0; i < this.DataSources.length; i++) {
                this.DropdownDataSources.push(`${this.DataSources[i].Name} (${this.DataSources[i].SourceType})`);
            }
        }

        this.StatusHistoryComments = [];
        if (this.Comments) {
            let statusUpdates = this.Comments.filter(comment => comment.IsStatusUpdate);
            /*if (statusUpdates.length > 0) {
                console.log(statusUpdates)
                let testDate = new Date(statusUpdates[0].CreateDate);
                console.log(testDate);
                console.log(testDate.getTime());
            }*/
            for (let i = 0; i < statusUpdates.length; i++) {
                let daysInStatus = -1;
                if (statusUpdates[i + 1]) {
                    let dateDiff = new Date(statusUpdates[i + 1].CreateDate).getTime() - new Date(statusUpdates[i].CreateDate).getTime();
                    daysInStatus = Math.round(dateDiff / (1000 * 3600 * 24));
                }
                else {
                    let dateDiff = new Date().getTime() - new Date(statusUpdates[i].CreateDate).getTime();
                    daysInStatus = Math.round(dateDiff / (1000 * 3600 * 24));
                }

                this.StatusHistoryComments.push(new StatusHistory(statusUpdates[i], daysInStatus));
            }
        }

    }

    constructor() {

    }

    matchesFilter(status: any, contacts: any, researchProjects: any, dataSources: any): boolean {

        if (!DoArraysOverlap(status, this.Form.Status)) {
            return false;
        }
        if (contacts.length > 0 && !DoArraysOverlap(contacts, this.DropdownContacts)) {
            return false;
        }
        if (!DoArraysOverlap(researchProjects, this.DropdownResearchProjects)) {
            return false;
        }
        if (dataSources.length > 0 && !DoArraysOverlap(dataSources, this.DropdownDataSources)) {
            return false;
        }

        return true;
    }
}

export class PacrComment {
    Id: number;
    PacrFormId: number;
    IsStatusUpdate: boolean;
    Comment: string;
    CreateDate: Date;
    PreviousStatus: string;
    NewStatus: string;
    SubmitterFirstName: string;
    SubmitterLastName: string;
    SubmitterEmail: string;

    constructor() {

    }
}

export class StatusHistory {
    Id: number;
    Status: string;
    DaysInStatus: number;
    CreateDate: Date;
    DisplayCreateDate: string;
    Feedback: string;

    constructor(comment: PacrComment, daysInStatus: number) {
        this.Id = comment.Id;
        this.Status = comment.NewStatus;
        this.CreateDate = comment.CreateDate;
        this.Feedback = comment.Comment;

        this.DaysInStatus = daysInStatus;

        this.DisplayCreateDate = FormatDate(this.CreateDate);
    }
}



