import { plainToClass } from "class-transformer";
import { UserDirectoryEntity } from "./user-directory.objects";
import { TableColumnVisibilityController, TableColumnGroup } from "../../../shared/scripts/table-column-visibility";
import { Unique } from "../../../shared/scripts/array-functions";
import { UserExperienceEntry, UserExperience, ExpertiseArea } from "../../../auth/pages/profile/scripts/expertise.objects";



export class UserDirectoryPage {

    users: UserDirectoryEntity[];
    dataTable: any;
    tableColumnController: TableColumnVisibilityController;
    filterDropdownIds: string[];
    visibleUserEmails: string;

    constructor(userJson: any, userExpertiseJson: any) {
        $(() => {

            //console.log(userJson);


            this.users = plainToClass(UserDirectoryEntity, <UserDirectoryEntity[]>userJson);

            let expertiseList: UserExperienceEntry[] = plainToClass(UserExperienceEntry, <UserExperienceEntry[]>userExpertiseJson);
            expertiseList.map((entry: UserExperienceEntry) => {
                let user: UserDirectoryEntity = this.users.find((user: UserDirectoryEntity) => user.Email == entry.Email);
                if (user != undefined) {
                    user.Expertise = plainToClass(UserExperience, <UserExperience>JSON.parse(entry.ExperienceJson));
                    user.HiddenFilter = user.Expertise.getHiddenFilter();
                    //console.log(user);
                }
            });

            this.users = this.users.map((user: UserDirectoryEntity) => {
                user.initialize();
                return user;
            });

            this.filterDropdownIds = ['#affiliation-dropdown', '#institute-dropdown', '#expertise-general-dropdown', '#expertise-analysis-dropdown', '#expertise-harm-reduction-dropdown', '#expertise-population-dropdown'];
            this.initializeFilters();

            this.setupTabWatcher();

            this.initializeTable();
        });
    }

    initializeFilters(): void {
        this.initializeDropdown('#affiliation-dropdown', Unique(this.users.map((user: UserDirectoryEntity) => user.Affiliation), true));
        this.initializeDropdown('#institute-dropdown', Unique(this.users.map((user: UserDirectoryEntity) => user.Institution), true));
        this.initializeDropdown('#country-dropdown', Unique(this.users.map((user: UserDirectoryEntity) => user.Country), true, ["Unknown"]));

        let expertiseAreas: ExpertiseArea[] = ExpertiseArea.GetExpertiseAreas();

        this.initializeDropdown('#expertise-general-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("general")), false);
        this.initializeDropdown('#expertise-analysis-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("analysis")), false);
        this.initializeDropdown('#expertise-harm-reduction-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("harm-reduction")), false);
        this.initializeDropdown('#expertise-population-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("population")), false);
    }

    initializeDropdown(selectId: string, values: string[], compoundValues: ExpertiseArea[] = undefined, selected: boolean = true): void {
    //initializeDropdown(selectId: string, values: string[], compoundValues: any[] = undefined, selected: boolean = true): void {
        $(selectId).selectpicker({
            dropupAuto: false,
            selectedTextFormat: "count",

            countSelectedText: (numSelected, numTotal) => {
                if (numSelected == numTotal) {
                    return "All";
                }
                else {
                    return numSelected + " Selected";
                }
            }
        });

        if (compoundValues == undefined) {
            values.forEach((element: any) => {
                $(selectId).append(new Option(element, element, false, selected));
            });
        }
        else {
            compoundValues.forEach((element: ExpertiseArea) => {
                $(selectId).append(new Option(element.Expertise, element.Id, false, selected));
            });
        }

        $(selectId).selectpicker("refresh");

        $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            this.filterUserDirectory();
        });
    }

    setupTabWatcher(): void {
        $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {

            if (e.currentTarget.id == "membership-tab") {
                ['#affiliation-dropdown', '#institute-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
            else if (e.currentTarget.id == "expertise-tab") {
                ['#expertise-general-dropdown', '#expertise-analysis-dropdown', '#expertise-harm-reduction-dropdown', '#expertise-population-reduction'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
        });
    }


    initializeTable(): void {
        this.dataTable = $('#user-directory-table').DataTable({
            "dom": '<"top-controls"<"column-select"><"search-bar"f><"spacer"><"count-found"B>>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: true,
            scrollY: '60vh',
            scrollCollapse: true,
            orderCellsTop: true,
            fixedColumns: {
                leftColumns: 2
            },
            columns: [
                { data: { _: "DisplayName", sort: "SortName" }, title: "Name", className: "text-left font-size12" },
                { data: { _: "DisplayEmail", sort: "Email" }, title: "Email", className: "text-left font-size12" },
                { data: { _: "DisplayAffiliation", sort: "Affiliation" }, title: "HRRN Affiliation", className: "project text-left font-size12" },
                { data: { _: "ProjectRole", sort: "ProjectRole" }, title: "HRRN Role", className: "project text-left font-size12" },
                { data: { _: "DisplayInstitution", sort: "Institution" }, title: "Institution", className: "location text-left font-size12" },
                //{ data: { _: "DisplayCountry", sort: "Country" }, title: "Country", className: "location text-left font-size12" },
                //{ data: { _: "Phone", sort: "Phone" }, title: "Phone Number", className: "about text-left font-size12" },
                //{ data: { _: "DisplayBio", sort: "LinkToProfessionalBio" }, title: "Bio", className: "about text-left font-size12" },
                { data: "ExpertiseGeneral", title: "<span class='nowrap'>General</span> Experience", className: "expertise text-left font-size12" },
                { data: "ExpertiseAnalysis", title: "<span class='nowrap'>Analysis</span> Experience", className: "expertise text-left font-size12" },
                { data: "ExpertiseHarmReduction", title: "<span class='nowrap'>Harm Reduction</span> Experience", className: "expertise text-left font-size12" },
                { data: "ExpertisePopulation", title: "<span class='nowrap'>Population</span> Experience", className: "expertise text-left font-size12" },
                //{ data: "ExpertiseBacteria", title: "<span class='nowrap'>Bacterial and Fungal</span> NTD Experience", className: "expertise text-left font-size12" },
            ],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    //exportOptions: {
                    //    columns: [1, 2, 3, 4]
                    //}
                },
                {
                    text: '<i class="fas fa-envelope"></i>',
                    titleAttr: 'Send Email',
                    action: (e, dt, node, config) => {
                        //console.log(this.visibleUserEmails);

                        navigator.clipboard.writeText(this.visibleUserEmails).then(
                            () => {
                                $('#directory-email-modal').modal('show');
                                $('#modal-email-list').text(this.visibleUserEmails);
                            });
                    }
                }
            ],
            data: this.users
        });

        $(".top-controls").addClass('row mx-0');

        $(".column-select").addClass('col-12 col-md-3 px-0');
        $(".column-select").empty().html('<div id="column-select"></div>');

        $(".search-bar").addClass('col-12 col-md-3');

        $(".spacer").addClass('col-12 col-md-3'); 0
        $(".count-found").addClass('col-12 col-md-3 d-flex justify-content-end vertical-align-middle align-self-end mt-2 pr-0');
        //<span id="people-found-count" class="font-italic font-size12" > </span>
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"><span id=\"people-found-count\"></span></div>');
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"></div>');

        this.tableColumnController = new TableColumnVisibilityController('#user-directory-table', '#column-select', [
            new TableColumnGroup("Project Details", true, [], "project"),
            new TableColumnGroup("Location", true, [], "location"),
            //new TableColumnGroup("About", false, [], "about"),
            new TableColumnGroup("Expertise", false, [], "expertise")
        ]);

        $('#user-directory-table').on('search.dt', (e, settings) => {
            this.createEmailList(this.dataTable.rows({ search: 'applied' }).data());
            this.setPeopleFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });
        this.createEmailList(this.users);
        this.setPeopleFoundLabel(this.users.length);
    }

    updateTable(matchingUsers: UserDirectoryEntity[]): void {

        this.dataTable.clear();
        this.dataTable.rows.add(matchingUsers).draw();
        this.setPeopleFoundLabel(matchingUsers.length);
        this.createEmailList(matchingUsers);
    }

    filterUserDirectory(): void {

        let matchingUsers: UserDirectoryEntity[] = [];

        let userMatches: boolean;
        this.users.forEach((user: UserDirectoryEntity) => {

            userMatches = true; //must match all filters

            if (!user.matchesFilter(this.getSelectedDropdownValues('#affiliation-dropdown'),
                                    this.getSelectedDropdownValues('#institute-dropdown'),
                                    this.getSelectedDropdownValues('#expertise-general-dropdown'),
                                    this.getSelectedDropdownValues('#expertise-analysis-dropdown'),
                                    this.getSelectedDropdownValues('#expertise-harm-reduction-dropdown'),
                                    this.getSelectedDropdownValues('#expertise-population-dropdown')
                )) {
                userMatches = false;
            }

            if (userMatches) { matchingUsers.push(user); }
        });

        this.updateTable(matchingUsers);
    }

    getSelectedDropdownValues(selectId: string): any {
        //return $(selectId).selectpicker('val');
        return $(selectId).val();
    }

    setPeopleFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#people-found-count').text("Nobody Found"); break;
            case 1: $('#people-found-count').text("1 Person Found"); break;
            default: $('#people-found-count').text(count + " People Found");
        }
    }

    createEmailList(matchingUsers: UserDirectoryEntity[]): void {
        this.visibleUserEmails = matchingUsers.map((user: UserDirectoryEntity) => user.Email).join("; ");
        //console.log(this.visibleUserEmails);
    }


    resetFilters(): void {

        ['#affiliation-dropdown', '#institute-dropdown', '#country-dropdown'].forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });

        ['#expertise-general-dropdown', '#expertise-analysis-dropdown', '#expertise-harm-reduction-dropdown', '#expertise-population-dropdown'].forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('deselectAll');
            $(dropdownId).selectpicker('refresh');
        });

        this.dataTable.search('').draw();
        this.updateTable(this.users);
    }
}