import { plainToClass } from 'class-transformer';

var Highcharts = require('highcharts/highcharts');


Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

export class SiteMetricsChart {

    siteSubmissions: any[]
    chartId: string;
    siteMetricsChart: any;
    rpFullNameArray: string[];
    rpShortNameArray: string[];
    colorArray: string[];

    constructor(id: string, data: any[], callbackFn = () => { }) {

        //document.ready
        $(() => {
            this.chartId = id;
            this.siteSubmissions = data;

            this.rpFullNameArray = [
                "A Comparative Evaluation of Overdose Prevention Programs in New York City and Rhode Island (SAFER Study)",//0
                "A Network-Based, Mixed Methods Study to Identify and Support Multiple Overdose Responders and Inform Overdose Prevention Interventions (Project H.E.R.O.)", //1
                "Assessing the Reach, Effectiveness, and Implementation of Multiple Harm Reduction Interventions (SF Moon Study)", //2
                "Undoing Harm and Integrating Services (Project Unite)", //3
                "Harm Reduction Services Offered Through Mail-Delivery Expansion (HOME)", //4
                "Implementing and Evaluating the Impact of Novel Mobile Harm Reduction Services on Overdose Among Women Who Use Drugs (SHOUT)", //5
                "Mobile Health Strategies to Support Longitudinal Engagement in Harm Reduction Services (ENHANCE)", //6
                "Peers Expanding Engagement in Methamphetamine Harm-Reduction with Contingency Management (PEER-CM)", //7
                "Promoting Remote Harm Reduction and Secondary Services in Rural Settings (PROMOTE)", //8
                "Teaching Harm Reduction In Vulnerable Environments (THRIVE)", //9
                "Coordination Center", //10
                "NIH/NIDA" //11
            ];

            this.rpShortNameArray = [
                "SAFER Study (NYU/Brown)", //0
                "Project H.E.R.O. (UNR)", //1
                "SF Moon Study (RTI)", //2
                "Project Unite (NYU)", //3
                "HOME (Cornell)", //4
                "SHOUT (JHU)", //5
                "ENHANCE (UWM/Tulane)", //6
                "PEER-CM (OHSU)", //7
                "PROMOTE (UC/UIC)", //8
                "THRIVE (Penn)", //9,
                "HRRN-CC", //10
                "NIH" //11
            ];

            this.colorArray = [
                '#058DC7', //0
                '#50B432', //1
                '#ED561B', //2
                '#DDDF00', //3
                '#24CBE5', //4
                '#64E572', //5
                '#FF9655', //6
                '#FFF263', //7
                '#6AF9C4', //8
                '#C25594', //9
                '#9E46C0', //10
                '#C43B60' //11
            ];

            this.initializeChart();

            callbackFn();

        });

    }

    private initializeChart(): void {
        let obj = this;
        
      
        this.siteMetricsChart = Highcharts.chart(this.chartId, {
            chart: {
                type: 'bar',
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: this.rpShortNameArray,
                labels: {
                    style: {
                        color: 'black'
                    },
                    padding: 10
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Proposal Requests'
                },
                endOnTick: false, // helps with cropping data labels
                maxPadding: 0.2, // larger value has more space for data labels (default is 0.05)
                reversedStacks: false,
                labels: {
                    enabled: false
                },
                stackLabels: {
                    enabled: true,
                    formatter: function () {
                        return Highcharts.numberFormat(this.total, 0, '.', ',');
                    },
                    inside: false, //label issues
                }
            },
            legend: {
                enabled: true,
                reversed: false,
                title: {
                    text: ''
                },
                align: 'center',
                verticalAlign: 'top',
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                bar: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    dataLabels: { enabled: false },
                }
            },
            series: [{
                name: 'In Draft',
                data: this.siteSubmissions[0],
                color: '#058DC7'
            }, {
                name: 'Submitted',
                data: this.siteSubmissions[1],
                color: '#C25594'
            }, /*{
                name: 'In Review',
                data: this.siteSubmissions[2],
                color: '#DDDF00'
            }, {
                name: 'Approved',
                data: this.siteSubmissions[3],
                color: '#50B432'
            }, {
                name: 'Disapproved',
                data: this.siteSubmissions[4],
                color: '#ED561B'
            }*/
            ]
        });

        //console.log(this.siteMetricsChart);
    }

    

}

