import { InitializeFilterDropdown, GetSelectedDropdownValues, ResetDropdownFilters, IFilter } from '../../../../shared/scripts/filtering';
import { Unique } from '../../../../shared/scripts/array-functions';
import { HrrnContact, HrrnResearchProject, HrrnDataSource, PacrContact, HrrnFile } from './pacr-form.entities';
import { PacrComment } from '../../pacr-dashboard/scripts/pacr-dashboard.entities'
import { plainToClass } from 'class-transformer';
import { FormatDate } from '../../../../shared/scripts/date-functions';

export class PacrTable {

    tableId: string;
    changedElement: JQuery<HTMLElement>; // update table when this is changed
    type: string;
    dataList: any[] = [];
    contactList: HrrnContact[] = [];
    pacrContactList: PacrContact[] = [];
    researchProjectList: HrrnResearchProject[] = [];
    leadResearchProject: HrrnResearchProject;
    dataSourceList: HrrnDataSource[] = [];
    fileList: File[] = [];
    pacrFileList: HrrnFile[];
    pacrCommentList: PacrComment[];

    constructor(tableId: string, type: string = "contact", callbackFn: Function = () => { }) {
        //document.ready
        $(() => {
            this.tableId = tableId;
            this.type = type;

            callbackFn();
        });
    }

    updateData(dataList: any[]) {
        if (this.type == "contact") {
            let data = plainToClass(HrrnContact, <HrrnContact[]>dataList);
            data.forEach(contact => contact.initialize());
            this.dataList = data;
            this.contactList = this.dataList; 
            this.updateTable();
        }
        else if (this.type == "research-project") {
            let data = plainToClass(HrrnResearchProject, <HrrnResearchProject[]>dataList);
            this.dataList = data;
            this.researchProjectList = this.dataList;
            if (this.leadResearchProject) this.researchProjectList.unshift(this.leadResearchProject); // Put the lead Research Project on Top
            this.updateTable();
        }
        else if (this.type == "file") {
            this.dataList = dataList;
            this.fileList = this.dataList;
            this.updateTable();
        }
        else if (this.type == "data-source") {
            this.dataList = dataList;
            this.dataSourceList = this.dataList;
            this.updateTable();
        }
        else if (this.type == "simple") {
            this.dataList = dataList;
            this.updateTable();
        }
        else if (this.type == "pacr-contact") {
            this.dataList = dataList;
            this.pacrContactList = this.dataList;
            this.updateTable();
        }
        else if (this.type == "pacr-file") {
            this.dataList = dataList;
            this.pacrFileList = this.dataList;
            this.updateTable();
        }
        else if (this.type == "pacr-comment") {
            this.dataList = dataList;
            this.pacrCommentList = this.dataList;
            this.updateTable();
        }
        else {
            this.dataList = dataList;
        }
    }

    updateTable(): void {
        let currentTableId = this.tableId.replace("#", "");

        // Empty current table
        $(this.tableId).empty();

        // Generate Content
        let content = "";

        if (this.type == "contact") {
            for (let i = 0; i < this.contactList.length; i++) {
                if (this.contactList[i] == undefined || this.contactList[i] == null) continue;

                content +=
                `<div class="card">
                    <div class="card-header" id="${currentTableId}-heading-${i}">
                        <div class="row justify-content-between">
                            <div class="col-auto">
                                <div class="header-text">
                                    <a class="collapsed text-decoration-none d-flex align-items-center" data-toggle="collapse" data-target="#${currentTableId}-collapse-${i}" aria-expanded="false" aria-controls="${currentTableId}-collapse-${i}">
                                        <i class="fa fa-plus-circle expand-btn" aria-hidden="true"></i><span class="expand-text d-block pl-2">${this.contactList[i].FullName}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="${currentTableId}-collapse-${i}" class="collapse" aria-labelledby="${currentTableId}-heading-${i}" data-parent="${this.tableId}">
                        <div class="card-body">
                            <table class="table table-bordered pacr-table mb-0">  
                                <tbody>
                                    <tr>
                                        <td class="table-row-label" style="width: 150px">Name: </td>
                                        <td>${this.contactList[i].FullName}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Email: </td>
                                        <td>${this.contactList[i].Email}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Research Project: </td>
                                        <td>${this.contactList[i].Affiliation}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>`
            }
        }

        if (this.type == "research-project") {
            let rpNames: string[] = [];
            for (let i = 0; i < this.researchProjectList.length; i++) {
                if (this.researchProjectList[i] == undefined || this.researchProjectList[i] == null) continue;

                // Avoids potential duplicates
                if (rpNames.includes(this.researchProjectList[i].Name)) continue;
                else (rpNames.push(this.researchProjectList[i].Name))

                let rowContent = "";
                for (let j = 0; j < this.researchProjectList[i].PIs.length; j++) {
                    rowContent +=
                        `<tr>
                            <td>${this.researchProjectList[i].PIs[j].FullName}</td>
                        </tr>`
                }

                content +=
                    `<div class="card">
                        <div class="card-header" id="${currentTableId}-heading-${i}">
                            <div class="row justify-content-between">
                                <div class="col-auto">
                                    <div class="header-text">
                                        <a class="collapsed text-decoration-none d-flex align-items-center" data-toggle="collapse" data-target="#${currentTableId}-collapse-${i}" aria-expanded="false" aria-controls="${currentTableId}-collapse-${i}">
                                            <i class="fa fa-plus-circle expand-btn" aria-hidden="true"></i><span class="expand-text d-block pl-2">${this.researchProjectList[i].Name}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="${currentTableId}-collapse-${i}" class="collapse" aria-labelledby="${currentTableId}-heading-${i}" data-parent="${this.tableId}">
                            <div class="card-body">
                                <p>Principal Investigator(s):</p>
                                <table class="table table-bordered pacr-table mb-0">
                                    <tbody>
                                      ${rowContent}                                  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>`
            }
        }

        if (this.type == "file") {
            for (let i = 0; i < this.fileList.length; i++) {
                if (this.fileList[i] == undefined || this.fileList[i] == null) continue;

                let icon = this.getFileIcon(this.fileList[i].type);

                content +=
                    `<div class="card">

                        <div class="card-header" id="${currentTableId}-heading-${i}">
                            <div class="row justify-content-between">
                                <div class="col-auto">
                                    <div class="header-text">
                                        <a class="text-decoration-none d-flex align-items-center">
                                            ${icon}<span class="expand-text d-block pl-2 no-pointer">${this.fileList[i].name}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                 
                    </div>`
            }
        }

        if (this.type == "data-source") {
            for (let i = 0; i < this.dataSourceList.length; i++) {
                if (this.dataSourceList[i] == undefined || this.dataSourceList[i] == null) continue;

                content +=
                    `<div class="card">
                        <div class="card-header" id="${currentTableId}-heading-${i}">
                            <div class="row justify-content-between">
                                <div class="col-auto">
                                    <div class="header-text">
                                        <a class="collapsed text-decoration-none d-flex align-items-center" data-toggle="collapse" data-target="#${currentTableId}-collapse-${i}" aria-expanded="false" aria-controls="${currentTableId}-collapse-${i}">
                                            <i class="fa fa-plus-circle expand-btn" aria-hidden="true"></i><span class="expand-text d-block pl-2">${this.dataSourceList[i].DropdownDisplay}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="${currentTableId}-collapse-${i}" class="collapse" aria-labelledby="${currentTableId}-heading-${i}" data-parent="${this.tableId}">
                            <div class="card-body">
                                <table class="table table-bordered pacr-table mb-0">
                                    <tbody>
                                    <tr>
                                        <td class="table-row-label" style="width: 125px">Data Source: </td>
                                        <td>${this.dataSourceList[i].Name}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Source Type: </td>
                                        <td>${this.dataSourceList[i].SourceType}</td>
                                    </tr>                         
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>`
            }
        }

        if (this.type == "simple") {
            for (let i = 0; i < this.dataList.length; i++) {
                if (this.dataList[i] == undefined || this.dataList[i] == null) continue;

                content +=
                    `<div class="card">

                        <div class="card-header" id="${currentTableId}-heading-${i}">
                            <div class="row justify-content-between">
                                <div class="col-auto">
                                    <div class="header-text">
                                        <a class="text-decoration-none d-flex align-items-center">
                                            <span class="expand-text d-block no-pointer">${this.dataList[i]}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                 
                    </div>`

            }
        }

        if (this.type == "pacr-contact") {
            for (let i = 0; i < this.pacrContactList.length; i++) {
                if (this.pacrContactList[i] == undefined || this.pacrContactList[i] == null) continue;

                content +=
                    `<div class="card">
                    <div class="card-header" id="${currentTableId}-heading-${i}">
                        <div class="row justify-content-between">
                            <div class="col-auto">
                                <div class="header-text">
                                    <a class="collapsed text-decoration-none d-flex align-items-center" data-toggle="collapse" data-target="#${currentTableId}-collapse-${i}" aria-expanded="false" aria-controls="${currentTableId}-collapse-${i}">
                                        <i class="fa fa-plus-circle expand-btn" aria-hidden="true"></i><span class="expand-text d-block pl-2"><span class="font-weight-600">${this.pacrContactList[i].FirstName} ${this.pacrContactList[i].LastName}</span> (<span class="font-italic font-weight-500">${this.pacrContactList[i].ContactType}</span>)</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="${currentTableId}-collapse-${i}" class="collapse" aria-labelledby="${currentTableId}-heading-${i}" data-parent="${this.tableId}">
                        <div class="card-body">
                            <table class="table table-bordered pacr-table mb-0">  
                                <tbody>
                                    <tr>
                                        <td class="table-row-label" style="width: 150px">Name: </td>
                                        <td>${this.pacrContactList[i].FirstName} ${this.pacrContactList[i].LastName}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Role: </td>
                                        <td>${this.pacrContactList[i].ContactType}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Email: </td>
                                        <td>${this.pacrContactList[i].Email}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-row-label">Research Project: </td>
                                        <td>${this.pacrContactList[i].Affiliation}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>`
            }
        }

        if (this.type == "pacr-file") {
            for (let i = 0; i < this.pacrFileList.length; i++) {
                if (this.pacrFileList[i] == undefined || this.pacrFileList[i] == null) continue;

                content +=
                    `<div class="card">

                        <div class="card-header" id="${currentTableId}-heading-${i}">
                            <div class="row justify-content-between">
                                <div class="col-auto">
                                    <div class="header-text">
                                        <a class="text-decoration-none d-flex align-items-center" href="${this.pacrFileList[i].link}" target="_blank">
                                            <i class="${this.pacrFileList[i].icon} expand-btn"></i><span class="expand-text d-block pl-2">${this.pacrFileList[i].text}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                 
                    </div>`
            }
        }

        if (this.type == "pacr-comment") {
            for (let i = 0; i < this.pacrCommentList.length; i++) {
                if (this.pacrCommentList[i] == undefined || this.pacrCommentList[i] == null) continue;

                content +=
                    `<div class="card">

                        <div class="card-header" id="${currentTableId}-heading-${i}">
                            <div class="row justify-content-between">
                                <div class="col-12">
                                    <div class="header-text no-pointer">
                                        <div class="d-flex justify-content-between mb-2">
                                            <div class="font-weight-600">
                                                ${FormatDate(this.pacrCommentList[i].CreateDate)}
                                            </div>
                                            <div>
                                                ${this.pacrCommentList[i].IsStatusUpdate ? `<span>${this.pacrCommentList[i].PreviousStatus}</span> <i class="fa-solid fa-arrow-right px-3"></i> <span>${this.pacrCommentList[i].NewStatus}</span>` : ``}
                                            </div>
                                        </div>
                                        <div>
                                            <p>${this.pacrCommentList[i].Comment}</p>
                                        </div>
                                        <div class="font-italic">
                                            ${this.pacrCommentList[i].SubmitterFirstName} ${this.pacrCommentList[i].SubmitterLastName} (${this.pacrCommentList[i].SubmitterEmail})
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                 
                    </div>`
            }
        }

        // Append table
        $(this.tableId).append(content);

        // Custom functionality for show/hide items ($(this) = card-body)
        $(this.tableId).find(".collapse").on("show.bs.collapse", function () {
            $(this).siblings(".card-header").addClass("active");
        });

        $(this.tableId).find(".collapse").on("hide.bs.collapse", function () {
            $(this).siblings(".card-header").removeClass("active");
        });
    }

    private getFileIcon(fileType: string) {

        if (fileType.includes("image")) return `<i class="fas fa-file-image expand-btn"></i>`;
        if (fileType.includes("pdf")) return `<i class="fa-solid fa-file-pdf expand-btn"></i>`;
        if (fileType.includes("word")) return `<i class="fa-solid fa-file-word expand-btn"></i>`;
        if (fileType.includes("spreadsheet")) return `<i class="fa-solid fa-file-excel expand-btn"></i>`;
        if (fileType.includes("presentation")) return `<i class="fa-solid fa-file-powerpoint expand-btn"></i>`;
        if (fileType.includes("audio")) return `<i class="fa-solid fa-file-audio expand-btn"></i>`;
        if (fileType.includes("video")) return `<i class="fa-solid fa-file-video expand-btn"></i>`;

        return `<i class="fa-solid fa-file expand-btn"></i>`
    }    
 
}
