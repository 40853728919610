import { Author, HrrnPublication } from './publications.entities';
import { plainToClass } from 'class-transformer';
import { IFilter, InitializeFilterDropdown, GetSelectedDropdownValues } from "../../../shared/scripts/dropdown-functions";
import { Unique } from "../../../shared/scripts/array-functions";

export class PublicationsPage implements IFilter {
    publicationsArray: HrrnPublication[];
    dataTable: any;

    constructor(dataJson: any) {

        //document.ready
        $(() => {
            this.publicationsArray = plainToClass(HrrnPublication, <HrrnPublication[]>dataJson);
            //this.publicationsArray.sort((x1: HrrnPublication, x2: HrrnPublication) => x1.SortOrder - x2.SortOrder);
            this.publicationsArray.map((data: HrrnPublication) => {
                data.initialize();
                return data;
            });

            //console.log(this.publicationsArray);

            this.initializeTable();
            this.setEventsFoundLabel(this.publicationsArray.length);
        });

    }

    initializeTable(): void {
        this.dataTable = $('#publications-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls"<"reset-section">fB>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            //scrollY: '70vh',
            orderCellsTop: true,
            language:
            {
                search: "Search:",
            },
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '24px' },
                { data: "Title", title: "Title", className: "text-left font-size12 pl-0" },
                { data: "AuthorStringTruncated", title: "Author", className: "text-left font-size12" },
                { data: "JournalAbbreviation", title: "Journal", className: "text-left font-size12" },
                { data: "PublishedYear",title: "Year", className: "text-left font-size12" },
                { data: "ResearchCenter", title:"Research Project", className: "text-left font-size12" },
                { data: "AuthorStringFull", title: 'Authors', visible: false },
                { data: "PublishedMonthNumber", visible: false },
                { data: "Abstract", title: "Abstract", visible: false },
                { data: "MeshTermsString", title: "Keywords", visible: false },
                { data: "PublishedDateString", title: "PublicationDate", visible: false },
                { data: "JournalTitle", title: "JournalTitle", visible: false },
                { data: "DoiNumber", title: "DOI", visible: false },
                { data: "CombinedHiddenFilter", visible: false },
                { data: "JournalVolume", title: "JournalVolume", visible: false },
                { data: "JournalPages", title: "JournalPages", visible: false },
            ],
            buttons: [
                {
                extend: 'csv',
                text: '<i class="fas fa-file-download"></i>',
                titleAttr: 'CSV',
                charset: 'utf-8',
                //exportOptions: {
                //    columns: [1, 2, 3, 4]
                //}
                }
            ],
            data: this.publicationsArray,
            initComplete: () => {
                $("#publications-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        //on td.details-control removed
        $('#publications-table tbody').on('click', 'tr', (event) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(event.currentTarget);
            var row = this.dataTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                if (row.data() != undefined) {
                    // Open this row
                    row.child(this.createDetailRow(row.data())).show();
                    tr.addClass('shown');
                }
            }
        });

        $(".top-controls").addClass('row mx-0 flex-row');
        $("#publications-table_filter").addClass("mr-auto");
        $("#publications-table-dropdown").append($("#filter-dropdown"));
        $(".dt-buttons").before(`<span class="align-self-end pl-1 table-message" id="publications-found-count"></span>`);
        $(".dt-buttons").addClass("align-self-end mb-2");


        $('#publications-table').on('search.dt', (e, settings) => {
            this.setEventsFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

        this.initializeFilters();
    }

    initializeFilters(): void {
        InitializeFilterDropdown("#research-project-dropdown", Unique(this.publicationsArray.map(data => data.ResearchCenter), true), this);
        let authorsList: string[] = [];
        this.publicationsArray.map(data => {
            authorsList.push(...data.AuthorStringFull.split(";"))
        })
        InitializeFilterDropdown("#author-dropdown", Unique(authorsList, true), this);
        InitializeFilterDropdown("#journal-dropdown", Unique(this.publicationsArray.map(data => data.JournalAbbreviation), true), this);
        InitializeFilterDropdown("#year-dropdown", Unique(this.publicationsArray.map(data => data.PublishedYear), true), this);       
    }

    triggerFilter(): void {
        let filteredData = this.publicationsArray;

        // Research Project
        filteredData = filteredData.filter(item => GetSelectedDropdownValues("#research-project-dropdown").includes(item.ResearchCenter));

        // Author
        filteredData = filteredData.filter(item => {
            let selectedAuthors = GetSelectedDropdownValues("#author-dropdown");
            for (let i = 0; i < selectedAuthors.length; i++) {
                if (item.AuthorStringFull.split(";").includes(selectedAuthors[i])) {
                    return true;
                }
            }
            return false;
        });

        // Journal
        filteredData = filteredData.filter(item => GetSelectedDropdownValues("#journal-dropdown").includes(item.JournalAbbreviation));

        // Year
        filteredData = filteredData.filter(item => GetSelectedDropdownValues("#year-dropdown").includes(item.PublishedYear));

        this.updateData(filteredData);
    }

    resetFilters(): void {
        ["#research-project-dropdown", "#author-dropdown", "#journal-dropdown", "#year-dropdown"].forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });
    }

    createDetailRow(data: HrrnPublication): string {
        // `data` is the original data object for the row
        let detailRow: string = '<table cellpadding="4" cellspacing="0" border="0" style="padding-left:75px; width: 100%">';

        detailRow += `
            <tr class="detail-row">
                <td class="text-left font-size12"><i>Title</i></td>
                <td class="text-left">${data.Title}</td> 
            </tr>
            <tr class="detail-row">
                <td class="text-left font-size12"><i>Journal</i></td>
                <td class="text-left">${data.JournalTitle}</td> 
            </tr>
            <tr class="detail-row">
                <td class="text-left font-size12"><i>Published</i></td>
                <td class="text-left">${data.PublishedDateString}</td> 
            </tr>
            <tr class="detail-row">
                <td class="text-left font-size12"><i>Authors</i></td>
                <td class="text-left">${data.AuthorStringFull}</td> 
            </tr>
            <tr class="detail-row">
                <td class="text-left font-size12"><i>Abstract</i></td>
                <td class="text-left">${data.Abstract}</td> 
            </tr>
            <tr class="detail-row">
                <td class="text-left font-size12"><i>Keywords</i></td>
                <td class="text-left">${data.MeshTermsString}</td> 
            </tr>
            <tr class="detail-row">
                <td class="text-left font-size12"><i>DOI</i></td>
                <td class="text-left">${data.DoiNumber}</td> 
            </tr>
        `;

        if (data.Source == "pubmed") {
            detailRow += `
                <tr class="detail-row">
                    <td></td>
                    <td class="text-right">
                        <a href="${data.Link}" target="_blank" class="butn small"><span>View Publication</span></a>
                    </td>
                </tr>
            `
        }


        detailRow += '</table>';

        return detailRow;
    }

    updateData(data: HrrnPublication[]): void {
        this.dataTable.clear();
        this.dataTable.rows.add(data);
        this.dataTable.draw();
    }

    setEventsFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#publications-found-count').text("No Publications Found"); break;
            case 1: $('#publications-found-count').text("1 Publications Found"); break;
            default: $('#publications-found-count').text(count + " Publications Found");
        }
    }
}
