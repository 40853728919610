import { plainToClass } from 'class-transformer';
export class HrrnPublication {

    PubMedId: string;
    AwardNumber: string;
    DoiNumber: string = "Not Available";
    Title: string;
    JournalISSN: string;
    JournalTitle: string;
    JournalAbbreviation: string;
    JournalVolume: string = "";
    JournalIssue: string = "";
    JournalPages: string = "";
    PublishedMonthName: string;
    PublishedMonthNumber: number;
    PublishedDay: string;
    PublishedYear: string;
    Abstract: string = "Not Available";
    MeshTerms: string[];
    MeshTermsString: string;
    Authors: Author[];
    AuthorStringFull: string = "";
    AuthorStringTruncated: string = "";

    ResearchCenter: string;
    Source: string;
    Link: string;
    Filename: string;

    PublishedDateString: string = "";
    CombinedHiddenFilter: string = "";

    constructor() { }

    initialize(): void {

        this.Authors = plainToClass(Author, <Author[]>this.Authors);

        let authorsFound: number = 0;
        this.Authors.forEach((author: Author) => {
            let label: string = "";
            if (author.LastName?.length > 0) { label += author.LastName; }
            if (author.Initials?.length > 0) { label += ", " + author.Initials; }

            if (this.AuthorStringFull.length > 0) { this.AuthorStringFull += "; "; }
            this.AuthorStringFull += label;

            if (++authorsFound <= 4) {
                if (this.AuthorStringTruncated.length > 0) { this.AuthorStringTruncated += "; "; }
                this.AuthorStringTruncated += label;
            }
        });
        if (this.AuthorStringTruncated != this.AuthorStringFull) { this.AuthorStringTruncated += ", et. al."; }

        if (this.MeshTerms != undefined && this.MeshTerms.length > 0) {
            this.MeshTermsString = this.MeshTerms.join("; ");
        }
        else { this.MeshTermsString = "Not Available"; }

        this.PublishedDateString = "";
        if (this.PublishedMonthName != undefined) {
            this.PublishedDateString = this.PublishedMonthName;
        }
        if (this.PublishedDay != undefined) {
            this.PublishedDateString += " " + this.PublishedDay + ",";
        }
        if (this.PublishedYear != undefined) this.PublishedDateString += " " + this.PublishedYear;
        this.PublishedDateString = this.PublishedDateString.trim();

        if (this.Source == "pubmed") {
            this.Link = "https://pubmed.ncbi.nlm.nih.gov/" + this.PubMedId;
        }
        else if (this.Source == "local") {
            this.Link = "/documents/publications/" + this.Filename;
        }

        this.CombinedHiddenFilter = this.Abstract + " " + this.MeshTermsString + " " + this.JournalTitle + " " + this.PubMedId + " " + this.DoiNumber;
    }
}

export class Author {
    LastName: string;
    Initials: string;
    Affiliation: string;

    constructor() { }
} 