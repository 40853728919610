import { plainToClass } from 'class-transformer';
import { PacrComment, PacrDashboardItem, StatusHistory } from './pacr-dashboard.entities';
import { PacrTable } from '../../pacr-form/scripts/pacr-table';
import { TableColumnGroup, TableColumnVisibilityController } from "../../../../shared/scripts/table-column-visibility";
import { IFilter, InitializeFilterDropdown, ResetDropdownFilters, GetSelectedDropdownValues } from "../../../../shared/scripts/filtering";
import { FormatNumberWithCommas } from '../../../../shared/scripts/number-functions';
import { Unique } from "../../../../shared/scripts/array-functions";

declare var $: any;

export class PacrDashboardPage implements IFilter {
    PacrDashboardItems: PacrDashboardItem[];
    currentDashboardItem: PacrDashboardItem;
    tableId: string = "pacr-dashboard-table";
    tableWrapperId: string = this.tableId + "_wrapper";
    tableColumnController: TableColumnVisibilityController;
    dataTable: any;
    adminStatusHistoryTable: any;
    adminStatusHistoryTableId: string = "admin-status-history-table";

    DetailsLeadProjectTable: PacrTable;
    DetailsAssociatedProjectsTable: PacrTable;
    DetailsContactsTable: PacrTable;
    DetailsAssociatedPITable: PacrTable;
    DetailsCoInvestigatorsTable: PacrTable;
    DetailsDataSoureTable: PacrTable;
    DetailsFileTable: PacrTable;

    AdminCommentTable: PacrTable;

    currentUserEmail: string;
    isAdmin: boolean;

    constructor(DashboardItems: any, CurrentUserEmail: any, IsAdmin: any, UserGuideFileJson: any) {

        //document.ready
        $(() => {
            this.isAdmin = IsAdmin;
            this.currentUserEmail = CurrentUserEmail;

            this.PacrDashboardItems = plainToClass(PacrDashboardItem, <PacrDashboardItem[]>DashboardItems);
            this.PacrDashboardItems.map(item => {
                item.initialize();
                return item;
            });

            this.initializeDropdowns();
            this.initializeDataTable();
            this.initializePacrTables();
            this.initializeAdminModal();

            // For Multiple Modals
            $('body').on('hidden.bs.modal', function () {
                if ($('.modal.show').length > 0) {
                    $('body').addClass('modal-open');
                }
            });

            // Don't close the alert modal by clicking on background
            $('#pacr-alert-modal').modal({ backdrop: 'static', keyboard: false, show: false });

            // Connect User Guide
            if (UserGuideFileJson.length > 0) {
                $("#user-guide-btn").prop("href", UserGuideFileJson[0].link);
            }
            else {
                $("#user-guide-btn").addClass("d-none");
            }
  
        });

    }

    showHelpModal(): void {
        $("#pacr-help-modal").modal('show');
    }

    showDetailsModal(): void {
        $("#pacr-details-modal").modal('show');
    }

    showAdminModal(): void {
        $("#pacr-details-modal").modal('hide');

        // Set Active Tab to Status History
        $("#admin-tab-content").find(".tab-pane").not("#admin-status-history").removeClass("active");
        $("#admin-tab-content").find(".tab-pane").not("#admin-status-history").removeClass("show");
        $("#admin-status-history-tab").trigger('click');

        // Clear Out any Input Data
        this.resetAdminValidation();
        $("#feedback-comment").val("");
        $("#admin-comment").val("");


        $("#pacr-admin-modal").modal('show');
        
    }

    showAlertModal(): void {

        $("#pacr-admin-modal").modal('hide');
        $("#pacr-alert-modal").modal('show');

        // Show 'x' for alert modal
        $("#pacr-alert-modal").find(".close").removeClass("d-none");
    }

    showDeleteModal(): void {
        $("#pacr-details-modal").modal('hide');

        $("#pacr-alert-title").text("Confirm Delete");
        $("#alert-modal-text").html(
            `<div>
                Are you sure you want to delete the proposal: ${this.currentDashboardItem.Form.PacrFormId}?
            </div>
            <div class="row justify-content-center mt-4">
                <div class="col-auto">
                    <button type="button" class="btn btn-danger" id="pacr-confirm-delete-btn" onclick="page.deletePacrForm()">Confirm</button>
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
            </div>`
        );

        $("#pacr-alert-modal").modal('show');
    }

    showUpdatingSpinner(): void { 
        $("#pacr-alert-modal").find(".close").addClass("d-none");

        $("#pacr-admin-modal").modal('hide');
        $("#pacr-alert-title").text("Updating");
        $("#alert-modal-text").html(
            `<div class= "text-center">
                <h6>Updating <span class= "pl-1"> <i class="fa-solid fa-spinner spin"> </i></span></h6>
            </div>`
        );
        $("#pacr-alert-modal").modal('show');
    }

    navigateEditForm(): void {
        window.location.href = `../pacrs/pacr-form/${this.currentDashboardItem.Form.Id}`;
    }

    initializeDropdowns(): void {
        let pacrStatuses: string[] = ["In Draft", "Submitted"];
        InitializeFilterDropdown("#pacr-status-dropdown", pacrStatuses, this);

        let rpArray = [];
        this.PacrDashboardItems.map(item => rpArray = rpArray.concat(item.DropdownResearchProjects));
        InitializeFilterDropdown("#pacr-project-dropdown", Unique(rpArray, true), this);

        let contactArray = [];
        this.PacrDashboardItems.map(item => contactArray = contactArray.concat(item.DropdownContacts));
        InitializeFilterDropdown("#pacr-contact-dropdown", Unique(contactArray, true), this, undefined, false);

        let dataSourceArray = [];
        this.PacrDashboardItems.map(item => dataSourceArray = dataSourceArray.concat(item.DropdownDataSources));
        InitializeFilterDropdown("#pacr-data-source-dropdown", Unique(dataSourceArray, true), this, undefined, false);
    }

    initializePacrTables(): void {
        this.DetailsLeadProjectTable = new PacrTable("#details-lead-research-project-table", "simple");
        this.DetailsAssociatedProjectsTable = new PacrTable("#details-associated-rp-table", "simple");
        this.DetailsContactsTable = new PacrTable("#details-pacr-contacts-table", "pacr-contact");
        this.DetailsAssociatedPITable = new PacrTable("#details-associated-pi-table", "pacr-contact");
        this.DetailsCoInvestigatorsTable = new PacrTable("#details-coinvestigators-table", "pacr-contact");
        this.DetailsDataSoureTable = new PacrTable("#details-data-sources-table", "simple");
        this.DetailsFileTable = new PacrTable("#details-uploaded-files-table", "pacr-file");
        this.AdminCommentTable = new PacrTable("#admin-comment-table", "pacr-comment");
    }

    initializeDataTable(): void {
        let columns: any[] = [
            { data: { _: "Form.Id", display: "DisplayID" }, title: "ID", className: "text-left font-size12 nowrap" },
            { data: "Form.Status", title: "Status", className: "text-left font-size12 nowrap" },
            { data: { _: "Form.LeadLastName", display: "DisplayLeadResearcher"}, title: "Lead Researcher", className: "text-left font-size12 nowrap"},
            { data: "Form.Title", title: "Title", className: "text-left font-size12 nowrap" },
            { data: { _: "Form.AssociatedResearchProjects", display: "DisplayInvolvedResearchCenters" }, title: "Involved Research Projects", className: "involved-projects text-left font-size12 nowrap" },
            { data: "DisplayContacts", title: "Associated Contacts", className: "contacts text-left font-size12 nowrap" },
            { data: "DisplayDataSources", title: "Data Sources", className: "data-sources text-left font-size12 nowrap" },
            { data: { _: "Form.SubmissionDate", display: "DisplaySubmitDate" }, title: "Submission Date", className: "submission-date text-left font-size12 nowrap" },
            { data: { _: "Form.LastUpdateDate", display: "DisplayLastUpdatedDate" }, title: "Last Updated", className: "last-updated font-size12 nowrap" }
        ];

        let tableColumnGroups: TableColumnGroup[] = [
            new TableColumnGroup("Involved Research Projects", true, [], "involved-projects"),
            new TableColumnGroup("Associated Contacts", false, [], "contacts"),
            new TableColumnGroup("Data Sources", false, [], "data-sources"),
            new TableColumnGroup("Submission Date", true, [], "submission-date"),
            new TableColumnGroup("Last Updated", true, [], "last-updated"),
        ];

        this.dataTable = $('#' + this.tableId).DataTable({
            "dom": '<"top-controls"<"column-select"><"search-bar"f><"spacer"><"count-found"B>>rtip',
            autoWidth: true,
            info: false,
            paging: true,
            pagingType: 'simple',
            pageLength: 50,
            search: true,
            scrollX: true,
            scrollY: '70vh',
            scrollCollapse: true,
            orderCellsTop: true,
            fixedColumns: {
                leftColumns: 1
            },
            columns: columns,
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    //exportOptions: {
                    //    columns: [1, 2, 3, 4]
                    //}
                }
            ],
            order: [0, 'desc'],
            data: this.PacrDashboardItems
        });

        $("#" + this.tableWrapperId + " .top-controls").addClass('row mx-0');

        $("#" + this.tableWrapperId + " .column-select").addClass('col-12 col-md-3 px-0 pr-4');
        $("#" + this.tableWrapperId + " .column-select").empty().html('<div id="' + this.tableId + '-column-select"></div>');

        $("#" + this.tableWrapperId + " .search-bar").addClass('col-12 col-md-4 pl-2');

        $("#" + this.tableWrapperId + " .spacer").addClass('col-12 col-md-1');
        $("#" + this.tableWrapperId + " .count-found").addClass('col-12 col-md-4 d-flex justify-content-end vertical-align-middle align-self-end mt-2 pr-0');
        //<span id="people-found-count" class="font-italic font-size12" > </span>
        $("#" + this.tableWrapperId + " .count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"><span id=\"' + this.tableId + '-found-count\"></span></div>');
        $("#" + this.tableWrapperId + " .count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"></div>');

        this.tableColumnController = new TableColumnVisibilityController(('#' + this.tableId), ('#' + this.tableId + '-column-select'), tableColumnGroups);

        $('#' + this.tableId).on('search.dt', (e, settings) => {
            this.setCountFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });
        this.setCountFoundLabel(this.PacrDashboardItems.length);

        $(".view-details-link").on("click", (e) => {

            // Get the Table Row
            let targetRow = $(e.target).closest("tr");
            let viewItem: PacrDashboardItem = this.dataTable.row(targetRow).data();

            this.viewDetailsModal(viewItem);

        })
    }

    setCountFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#' + this.tableId + '-found-count').text("No Requests Found"); break;
            case 1: $('#' + this.tableId + '-found-count').text("1 Request Found"); break;
            default: $('#' + this.tableId + '-found-count').text(FormatNumberWithCommas(count + "") + " Requests Found");
        }
    }

    updateTable(matchingItems: PacrDashboardItem[]): void {

        this.dataTable.clear();
        this.dataTable.rows.add(matchingItems).draw();
        this.setCountFoundLabel(matchingItems.length);

        $(".view-details-link").on("click", (e) => {
            // Get the Table Row
            let targetRow = $(e.target).closest("tr");
            let viewItem: PacrDashboardItem = this.dataTable.row(targetRow).data();
            this.viewDetailsModal(viewItem);
        });
    }

    triggerFilter(): void {

        let matchingItems: PacrDashboardItem[] = [];
        this.PacrDashboardItems.map(item => {
            if (item.matchesFilter(GetSelectedDropdownValues("#pacr-status-dropdown"), GetSelectedDropdownValues("#pacr-contact-dropdown"), GetSelectedDropdownValues("#pacr-project-dropdown"), GetSelectedDropdownValues("#pacr-data-source-dropdown"))) {
                matchingItems.push(item);
            }
        });

        this.updateTable(matchingItems);

    }

    resetFilters(): void {
        ResetDropdownFilters(["#pacr-status-dropdown", "#pacr-project-dropdown"]);

        ["#pacr-contact-dropdown", "#pacr-data-source-dropdown"].forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('deselectAll');
            $(dropdownId).selectpicker('refresh');
        });

        this.clearSearchBar();

        this.updateTable(this.PacrDashboardItems);
    }

    clearSearchBar(): void {
        this.dataTable.search('').draw();
    }

    initializeAdminModal(): void {

        $("#new-status").on("change", () => {
            let updateNewStatusText = $("#new-status").val();
            if (updateNewStatusText != null) {
                $("#pacr-admin-modal").find(".update-status-new-text").text(updateNewStatusText.toString())
            }
        });

        $("#admin-update-status-btn").on("click", () => {
            let isValid = this.updateStatusValidation();
            if (isValid) {
                this.submitStatusUpdate();
            }
        });

        $("#admin-add-comment-btn").on("click", () => {
            let isValid = this.updateStatusValidation();
            if (isValid) {
                this.submitAddComment();
            }
        })

        this.initializeAdminStatusHistoryTable();
    }

    initializeAdminStatusHistoryTable(): void {
        let columns: any[] = [
            { data: "Status", title: "Status", className: "text-left font-size12 nowrap" },
            { data: "DaysInStatus", title: "Days In Status", className: "text-left font-size12 nowrap" },
            { data: { _: "Id", display: "DisplayCreateDate" }, title: "Date", className: "text-left font-size12 nowrap" },
            { data: "Feedback", title: "Feedback", className: "text-left font-size12 nowrap" }
        ];

        this.adminStatusHistoryTable = $('#' + this.adminStatusHistoryTableId).DataTable({
            //"dom": '<"top-controls"<"column-select"><"search-bar"f><"spacer"><"count-found"B>>rtip',
            "dom": "rtip",
            autoWidth: true,
            info: false,
            paging: false,
            //pagingType: 'simple',
            //pageLength: 50,
            search: false,
            scrollX: true,
            scrollY: '70vh',
            scrollCollapse: true,
            orderCellsTop: true,
            columns: columns,
            order: [2, 'desc'],
            data: []
        });

        $("#pacr-admin-modal").on('shown.bs.modal', () => {
            this.adminStatusHistoryTable.columns.adjust().draw();
        })
    }



    viewDetailsModal(item: PacrDashboardItem): void {
        this.currentDashboardItem = item;

        // Set Available Buttons
        $("#pacr-edit-form-btn").addClass("d-none");
        $("#pacr-delete-form-btn").addClass("d-none");
        if (this.isAdmin) {
            $("#pacr-delete-form-btn").removeClass("d-none");

            if (this.currentDashboardItem.Form.Status == "In Draft" || this.currentDashboardItem.Form.Status == "Submitted") {
                $("#pacr-edit-form-btn").removeClass("d-none");
            }
        }
        else {
            if (this.currentDashboardItem.Form.Status == "In Draft") {
                $("#pacr-edit-form-btn").removeClass("d-none");
                $("#pacr-delete-form-btn").removeClass("d-none");
            }
            else if (this.currentDashboardItem.Form.Status == "Submitted" && this.currentDashboardItem.Form.SubmitterEmail == this.currentUserEmail) {
                $("#pacr-edit-form-btn").removeClass("d-none");
                $("#pacr-delete-form-btn").removeClass("d-none");
            }
        }


        this.currentDashboardItem.Comments.sort((a, b) => b.Id - a.Id);

        // Populate Fields
        item.Form.PacrFormId ? $("#pacr-details-title").text(item.Form.PacrFormId) : $("#pacr-details.title").text("Unknown ID");

            // PARC Information
        item.Form.PacrFormId ? $("#details-pacr-id").text(item.Form.PacrFormId) : $("#details-pacr-id").text("");
        item.Form.Status ? $("#details-pacr-status").text(item.Form.Status) : $("#details-pacr-status").text("");
        item.DisplayLastUpdatedDate ? $("#details-last-updated").text(item.DisplayLastUpdatedDate) : $("#details-last-updated").text("");
        item.DisplaySubmitDate ? $("#details-submit-date").text(item.DisplaySubmitDate) : $("#details-submit-date").text("");
        if (item.Contacts) {
            this.DetailsContactsTable.updateData(item.Contacts);
        }
        else {
            this.DetailsContactsTable.updateData([]);
        }

            // General Information
        item.Form.ProposalType ? $("#details-proposal-type").text(item.Form.ProposalType) : $("#details-proposal-type").text("");
        item.Form.Title ? $("#details-proposal-title").text(item.Form.Title) : $("#details-proposal-title").text("");

            // Lead Researcher Information
        item.DisplayLeadResearcher ? $("#details-lead-research-name").text(item.DisplayLeadResearcher) : $("#details-lead-research-name").text("");
        item.Form.LeadEmail ? $("#details-lead-research-email").text(item.Form.LeadEmail) : $("#details-lead-research-email").text("");
        item.Form.LeadPhone ? $("#details-lead-research-phone").text(item.Form.LeadPhone) : $("#details-lead-research-phone").text("");
        if (item.Form.LeadResearchProjectName) {
            this.DetailsLeadProjectTable.updateData([item.Form.LeadResearchProjectName]);            
        }
        else {
            this.DetailsLeadProjectTable.updateData([]);
        }

        if (item.Contacts) {
            let associatedPIs = item.Contacts.filter(contact => contact.ContactType == "Lead Researcher PI");
            this.DetailsAssociatedPITable.updateData(associatedPIs);
        }
        else {
            this.DetailsContactsTable.updateData([]);
        }

        // Involved Research Centers & Collaborators
        if (item.Form.AssociatedResearchProjects) {
            this.DetailsAssociatedProjectsTable.updateData(item.Form.AssociatedResearchProjects.split("|"));
        }
        else {
            this.DetailsAssociatedProjectsTable.updateData([]);
        }

        if (item.Contacts) {
            let coInvestigators = item.Contacts.filter(contact => contact.ContactType == "Co-Investigator / Collaborator");
            this.DetailsCoInvestigatorsTable.updateData(coInvestigators);
        }
        else {
            this.DetailsCoInvestigatorsTable.updateData([]);
        }

        // Proposal Details
        item.Form.ProposalSummary ? $("#details-proposal-summary").text(item.Form.ProposalSummary) : $("#details-proposal-summary").text("");
        item.Form.ProposalSpecificAims ? $("#details-proposal-specific-aims").text(item.Form.ProposalSpecificAims) : $("#details-proposal-specific-aims").text("");
        item.Form.PrimarySecondaryOutcomes ? $("#details-primary-secondary-outcomes").text(item.Form.PrimarySecondaryOutcomes) : $("#details-primary-secondary-outcomes").text("");
        item.Form.ResearchMethodology ? $("#details-research-methodology-summary").text(item.Form.ResearchMethodology) : $("#details-research-methodology-summary").text("");
        item.Form.DataHandling ? $("#details-data-handling-procedures").text(item.Form.DataHandling) : $("#details-data-handling-procedures").text("");
        item.Form.IrbRequirements ? $("#details-irb-requirements").text(item.Form.IrbRequirements) : $("#details-irb-requirements").text("");
        item.Form.ExpectedTimeline ? $("#details-expected-timeline").text(item.Form.ExpectedTimeline) : $("#details-expected-timeline").text("");
        if (item.DropdownDataSources) {
            this.DetailsDataSoureTable.updateData(item.DropdownDataSources);
        }
        else {
            this.DetailsDataSoureTable.updateData([]);
        }

        if (item.Files) {
            this.DetailsFileTable.updateData(item.Files);
        }
        else {
            this.DetailsFileTable.updateData([]);
        }

        // Additional Information
        item.Form.CCSupport ? $("#details-cc-support").text(item.Form.CCSupport) : $("#details-cc-support").text("");
        item.Form.Comments ? $("#details-additional-comments").text(item.Form.Comments) : $("#details-additional-comments").text("");
        
        // Show Modal
        this.showDetailsModal();

    }

    viewAdminModal(): void {
        let adminModal = $("#pacr-admin-modal");

        // Set Title
        $("#pacr-admin-title").text(this.currentDashboardItem.Form.PacrFormId);

        // Set Status History
        this.adminStatusHistoryTable.clear();
        this.adminStatusHistoryTable.rows.add(this.currentDashboardItem.StatusHistoryComments).draw();

        // Set Current Status
        if (this.currentDashboardItem.Form.Status) {
            $("#new-status").val(this.currentDashboardItem.Form.Status).trigger("change");
            adminModal.find(".update-status-current-text").text(this.currentDashboardItem.Form.Status);
        }
        else {
            $("#new-status").val("").trigger("change");
            adminModal.find(".update-status-current-text").text("Unknown Status");
        }

        // Set Admin Comments
        if (this.currentDashboardItem.Comments) {
            this.AdminCommentTable.updateData(this.currentDashboardItem.Comments);
        }
        else {
            this.AdminCommentTable.updateData([]);
        }

        // Show Modal
        this.showAdminModal();

    }

    updateStatusValidation(): boolean {
        // Reset any existing validation classes
        this.resetAdminValidation();

        let isValid = true;

        // Get All Visible input, textarea, select fields that are required
        let requiredFields = $(".required-field:visible");

        // For each element, check if it is valid
        requiredFields.each(function () {
            if ($(this).val() && $(this).val() != "") {
                $(this).addClass("is-valid");
            }
            else {
                $(this).addClass("is-invalid");
                $(this).siblings(".error-text").removeClass("d-none");
                isValid = false;
            }
        });

        if ($("#new-status:visible").length > 0) {
            // Check and See if new and previous status' are different
            let currentStatus = $("#admin-update-status").find(".update-status-current-text").first().text();
            let newStatus = $("#admin-update-status").find(".update-status-new-text").first().text();

            if (currentStatus == newStatus) {
                isValid = false;
                $("#new-status").siblings("button").addClass("is-invalid");
                $(".new-status-error").removeClass("d-none");
            }
            else {
                $("#new-status").siblings("button").addClass("is-valid");
            }
        }



        return isValid;
    }

    resetAdminValidation(): void {
        // Get ALL input, textarea, select fields that are required (all sections)
        let requiredFields = $(".required-field");

        // remove 'is-valid' and 'is-invalid' classes
        requiredFields.removeClass("is-valid");
        requiredFields.removeClass("is-invalid");

        // hide error text
        requiredFields.siblings(".error-text").addClass("d-none");

        // special case
        $("#new-status").siblings("button").removeClass("is-invalid");
        $("#new-status").siblings("button").removeClass("is-valid");
        $(".new-status-error").addClass("d-none");

    }

    submitStatusUpdate(): void {

        let statusUpdateComment: PacrComment = {
            Id: 0,
            PacrFormId: this.currentDashboardItem.Form.Id,
            CreateDate: new Date(),
            Comment: $("#feedback-comment").val().toString(),
            IsStatusUpdate: true,
            NewStatus: $("#new-status").val().toString(),
            PreviousStatus: this.currentDashboardItem.Form.Status,
            SubmitterEmail: "", // set on backend
            SubmitterFirstName: "", // set on backend
            SubmitterLastName: "" // set on backend
        };

        // Reset Comment Input
        $("#feedback-comment").val("");

        // Show Loading...
        this.showUpdatingSpinner();

        $.ajax({
            url: '/secure/pacrs/pacr-dashboard/pacr-dashboard?handler=UpdateStatus',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(this.currentDashboardItem.Form), commentData: JSON.stringify(statusUpdateComment)},
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                //console.log(response);
                this.resetAdminValidation();

                // response is the added comment
                let returnedComment: PacrComment = plainToClass(PacrComment, response);

                // Add Status Update to Current Form
                let foundIndex: number = this.PacrDashboardItems.findIndex(item => item.Form.Id == this.currentDashboardItem.Form.Id);
                this.PacrDashboardItems[foundIndex].Comments.push(returnedComment);
                this.PacrDashboardItems[foundIndex].Comments.sort((a, b) => b.Id - a.Id);
                this.currentDashboardItem.StatusHistoryComments.push(new StatusHistory(returnedComment, 0));

                // Update the Comments Table & Status History Table
                this.adminStatusHistoryTable.clear();
                this.adminStatusHistoryTable.rows.add(this.currentDashboardItem.StatusHistoryComments).draw();
                this.AdminCommentTable.updateData(this.currentDashboardItem.Comments)

                // Update DataTable             
                this.PacrDashboardItems[foundIndex].Form.Status = $("#new-status").val().toString();
                this.triggerFilter();

                // Set Content for Alert Modal
                $("#pacr-alert-title").text("Status Updated!");
                $("#alert-modal-text").html(`
                    <p>The status of submission ${this.currentDashboardItem.Form.PacrFormId} has been updated.</p>
                    <div class="mt-3">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                `);

                // Hide Admin Modal + Show Alert Modal
                this.showAlertModal();

            },
            error: (response) => {
                //console.log("error", response);
                $("#pacr-alert-title").text("Oops!");
                $("#alert-modal-text").text(`An Error occured while attempting to update this proposal. Please try again later.`);
                alert("An unknown Error has occured. Please Try again later.")
            }
        });
        
    }

    submitAddComment(): void {

        let newComment: PacrComment = {
            Id: 0,
            PacrFormId: this.currentDashboardItem.Form.Id,
            CreateDate: new Date(),
            Comment: $("#admin-comment").val().toString(),
            IsStatusUpdate: false,
            NewStatus: this.currentDashboardItem.Form.Status,
            PreviousStatus: this.currentDashboardItem.Form.Status,
            SubmitterEmail: "", // set on backend
            SubmitterFirstName: "", // set on backend
            SubmitterLastName: "" // set on backend
        }

        // Reset Comment Input
        $("#admin-comment").val("");

        $.ajax({
            url: '/secure/pacrs/pacr-dashboard/pacr-dashboard?handler=AddComment',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { commentData: JSON.stringify(newComment) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                //console.log(response);
                this.resetAdminValidation();

                // response is the added comment
                let returnedComment: PacrComment = plainToClass(PacrComment, response);

                // Add Comment to Current Form
                let foundIndex = this.PacrDashboardItems.findIndex(item => item.Form.Id == this.currentDashboardItem.Form.Id);
                this.PacrDashboardItems[foundIndex].Comments.push(returnedComment);
                this.PacrDashboardItems[foundIndex].Comments.sort((a, b) => b.Id - a.Id);

                // Update the Comment Table
                this.AdminCommentTable.updateData(this.currentDashboardItem.Comments)

            },
            error: (response) => {
                console.log("error", response);
                $("#pacr-alert-title").text("Oops!");
                $("#alert-modal-text").text(`An Error occured while attempting to update this proposal. Please try again later.`);
                alert("An unknown Error has occured. Please Try again later.")
            }
        });

    }

    deletePacrForm(): void {

        // Show Loading...
        this.showUpdatingSpinner();

        $.ajax({
            url: '/secure/pacrs/pacr-dashboard/pacr-dashboard?handler=DeleteForm',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(this.currentDashboardItem.Form) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                //console.log(response);

                // Set Content for Alert Modal
                $("#pacr-alert-title").text("Proposal Deleted!");
                $("#alert-modal-text").html(`
                    <p>${this.currentDashboardItem.Form.PacrFormId} has been deleted.</p>
                    <div class="mt-3">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                `);

                // Update DataTable
                let foundIndex: number = this.PacrDashboardItems.findIndex(item => item.Form.Id == this.currentDashboardItem.Form.Id);
                this.PacrDashboardItems.splice(foundIndex, 1);
                this.currentDashboardItem = null;
                this.triggerFilter();

                // Hide Admin Modal + Show Alert Modal
                this.showAlertModal();

            },
            error: (response) => {
                console.log("error", response);
                $("#pacr-alert-title").text("Oops!");
                $("#alert-modal-text").text(`An Error occured while attempting to update this proposal. Please try again later.`);
                alert("An unknown Error has occured. Please Try again later.")
            }
        });

    }

    searchUserRequests(): void {
        this.resetFilters();

        let matchingItems: PacrDashboardItem[] = [];
        this.PacrDashboardItems.map(item => {
            if (item.Form.SubmitterEmail == this.currentUserEmail && item.Form.Status == "In Draft") {
                matchingItems.push(item);
            }
        });

        this.updateTable(matchingItems);

        $('html, body').animate({
            scrollTop: $("#pacr-dashboard-table").offset().top
        }, 1500);
    }

}
